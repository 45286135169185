<template>
  <a-modal
      :visible="editModelVisible"
      title="字典类型"
      :maskClosable="false"
      @cancel="cancelHandler"
  >
    <template v-slot:footer>
      <a-button @click="cancelHandler">取消</a-button>
      <a-popconfirm
          title="确定更新?"
          @confirm="handleUpdate"
      >
        <a-button type="primary" v-if="current.id" v-auth:sys-dict-update>更新</a-button>
      </a-popconfirm>
      <a-popconfirm
          title="确定提交?"
          @confirm="handleInsert"
      >
        <a-button type="primary" v-if="!current.id" v-auth:sys-dict-insert>提交</a-button>
      </a-popconfirm>
    </template>
    <a-form :model="current" :labelCol="{span: 4}" :wrapperCol="{span: 20}" ref="formRef" :rules="RULES.DICT_TYPE">
      <a-form-item label="字典代码" name="code">
        <a-input v-model:value="current.code" :readonly="current.id ? true : false"/>
      </a-form-item>
      <a-form-item label="字典名" name="name">
        <a-input v-model:value="current.name" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { reactive, watch, ref } from 'vue'
import ObjectUtil from '@/util/ObjectUtil'
import { dictApi } from '@/util/api'
import { RULES } from '@/constant'
export default {
  name: 'menu-edit-form',
  props: {
    id: {
      type: String,
      require: false
    },
    editModelVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['refresh', 'update:editModelVisible', 'update:id'],
  setup (props, context) {
    const current = reactive({})
    const formRef = ref()
    watch(() => props.id, async id => {
      if (id) {
        const res = await dictApi.getTypeById(id)
        if (res) {
          ObjectUtil.clearAttributesAndAssign(current, res.data)
        }
      } else {
        ObjectUtil.clearAttributes(current)
      }
    }, { deep: true, immediate: true })
    const handleUpdate = () => {
      formRef.value.validate(async () => {
        const res = await dictApi.updateType(current)
        if (res) {
          context.emit('update:editModelVisible', false)
          context.emit('update:id', null)
          context.emit('refresh')
        }
      })
    }
    const handleInsert = () => {
      formRef.value.validate(async () => {
        const res = await dictApi.insertType(current)
        if (res) {
          ObjectUtil.clearAttributes(current)
          context.emit('update:editModelVisible', false)
          context.emit('update:id', null)
          context.emit('refresh')
        }
      })
    }
    const cancelHandler = () => {
      ObjectUtil.clearAttributes(current)
      context.emit('update:editModelVisible', false)
      context.emit('update:id', null)
    }
    return {
      current,
      handleUpdate,
      handleInsert,
      cancelHandler,
      formRef,
      RULES
    }
  }
}
</script>

<style scoped>

</style>
