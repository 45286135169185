<template>
  <a-row :gutter="16">
    <a-col class="gutter-row" :span="10">
      <wj-card>
        <a-card :bordered="false">
          <template v-slot:title>
            <span>字典类型</span>
            <a-button type="link" v-auth:sys-dict-insert @click="typeInsertHandler">新增</a-button>
          </template>
          <a-table size="small" :dataSource="typeList" :columns="COLUMNS.DICT_TYPE" :loading="typeLoading" :rowKey="record=>record.id" :pagination="false">
            <template #bodyCell="{ column, record }">
              <template v-if="column.dataIndex === 'action'">
                <a-button type="text" @click="showItem(record)">字典项</a-button>
                <a-button type="link" @click="typeEditModalHandler(record)" v-auth:sys-dict-update>编辑</a-button>
                <a-popconfirm
                    title="确定删除?"
                    @confirm.stop="typeDeleteHandler(record.id)"
                >
                  <a-button type="link" danger v-auth:sys-dict-delete>删除</a-button>
                </a-popconfirm>
              </template>
            </template>
          </a-table>
        </a-card>
      </wj-card>
    </a-col>
    <a-col class="gutter-row" :span="14">
      <wj-card>
        <a-card :bordered="false">
          <template v-slot:title>
            <span style="font-weight: bold">{{itemTitle}}</span>
            <a-button type="link" v-auth:sys-dict-insert @click="itemInsertHandler">新增</a-button>
          </template>
          <a-table size="small" :dataSource="itemList" :columns="COLUMNS.DICT_ITEM" :loading="itemLoading" :rowKey="record=>record.id" :pagination="false">
            <template #bodyCell="{ column, record }">
              <template v-if="column.dataIndex === 'color'">
                <a-tag :color="record.color">{{record.name}}</a-tag>
              </template>
              <template v-if="column.dataIndex === 'action'">
                <a-button type="link" @click="itemEditModalHandler(record)" v-auth:sys-dict-update>编辑</a-button>
                <a-popconfirm
                    title="确定删除?"
                    @confirm="itemDeleteHandler(record.id)"
                >
                  <a-button type="link" danger v-auth:sys-dict-delete>删除</a-button>
                </a-popconfirm>
              </template>
            </template>
          </a-table>
        </a-card>
      </wj-card>
    </a-col>
  </a-row>
  <type-edit-form v-model:editModelVisible="typeEditModelVisible" v-model:id="typeIdModel" @refresh="searchType"/>
  <item-edit-form v-model:editModelVisible="itemEditModelVisible" v-model:id="itemIdModel" @refresh="showItem(currentType)" :current-type="currentType"/>
</template>

<script>
import typeEditForm from '@/views/sys/dict/page/typeEditForm'
import itemEditForm from '@/views/sys/dict/page/itemEditForm'
import { ref, onMounted, reactive } from 'vue'
import ObjectUtil from '@/util/ObjectUtil'
import { dictApi } from '@/util/api'
import { message } from 'ant-design-vue'
import { COLUMNS } from '@/constant'

export default {
  name: 'SysDict',
  components: { typeEditForm, itemEditForm },
  setup () {
    const itemTitle = ref('字典项区域')
    const typeList = ref([])
    const itemList = ref([])
    const typeLoading = ref(false)
    const itemLoading = ref(false)
    const typeEditModelVisible = ref(false)
    const itemEditModelVisible = ref(false)
    const clickedIndex = ref()
    const currentType = reactive({})
    const typeIdModel = ref()
    const itemIdModel = ref()
    const typeEditModalHandler = (record) => {
      typeEditModelVisible.value = true
      typeIdModel.value = record.id
    }
    const typeInsertHandler = () => {
      typeEditModelVisible.value = true
      typeIdModel.value = null
    }
    const itemInsertHandler = () => {
      if (currentType.id) {
        itemEditModelVisible.value = true
        itemIdModel.value = null
      } else {
        message.warn('请先选择字典类型')
      }
    }
    const itemEditModalHandler = (record) => {
      itemEditModelVisible.value = true
      itemIdModel.value = record.id
    }
    const showItem = async record => {
      itemTitle.value = record.name
      itemLoading.value = true
      currentType.id = record.id
      currentType.name = record.name
      currentType.code = record.code
      const res = await dictApi.itemList(record.code)
      itemLoading.value = false
      if (res) {
        itemList.value = res.data
      }
    }
    const searchType = async () => {
      ObjectUtil.clearAttributes(currentType)
      itemList.value = []
      typeLoading.value = true
      const res = await dictApi.typeList()
      typeLoading.value = false
      if (res) {
        typeList.value = res.data
      }
    }
    const typeDeleteHandler = async id => {
      if (await dictApi.deleteType(id)) {
        await searchType()
      }
    }
    const itemDeleteHandler = async id => {
      if (await dictApi.deleteItem(id)) {
        await showItem(currentType)
      }
    }
    onMounted(() => {
      searchType()
    })
    return {
      COLUMNS,
      itemTitle,
      typeList,
      itemList,
      typeLoading,
      itemLoading,
      showItem,
      clickedIndex,
      typeDeleteHandler,
      typeEditModalHandler,
      itemEditModalHandler,
      itemInsertHandler,
      itemDeleteHandler,
      searchType,
      typeEditModelVisible,
      itemEditModelVisible,
      typeIdModel,
      itemIdModel,
      typeInsertHandler,
      currentType
    }
  }
}
</script>

<style>

.clicked-index {
  background: lightblue;
}

</style>
