<template>
  <wj-card>
    <a-form
      layout="inline"
      :model="searchData"
    >
      <a-form-item label="用户名">
        <a-input v-model:value="searchData.username" placeholder="Username"></a-input>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="searchHandler">
          搜索
        </a-button>
        <a-button type="primary" @click="addModalHandler" v-auth:sys-user-insert>
          新增
        </a-button>
      </a-form-item>
    </a-form>
  </wj-card>
  <wj-card>
    <a-table size="small" :columns="COLUMNS.USER" :data-source="users" bordered :pagination="pagination" @change="paginationChangeHandler" :loading="loading" :rowKey="record=>record.id">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'roleNameList'">
          <a-tag color="blue" v-for="(item,index) in record.roleNameList" :key="index">{{item}}</a-tag>
        </template>
        <template v-if="column.dataIndex === 'isEnabled'">
          <a-tag :color="record.isEnabledTag.color">{{record.isEnabledTag.name}}</a-tag>
        </template>
        <template v-if="column.dataIndex === 'action'">
          <a-button type="link" @click="editModalHandler(record)">编辑</a-button>
          <a-popconfirm
            title="确定重置密码?"
            @confirm="resetPasswordHandler(record.id)"
          >
            <a-button type="link" danger v-auth:sys-user-reset-password>重置密码</a-button>
          </a-popconfirm>
          <a-popconfirm
            title="确定删除?"
            @confirm="deleteHandler(record.id)"
          >
            <a-button type="link" danger v-auth:sys-user-delete>删除</a-button>
          </a-popconfirm>
        </template>
      </template>
    </a-table>
  </wj-card>
  <user-edit-modal v-model:editModelVisible="editModelVisible" v-model:userId="modalUserId" @refresh="refresh"></user-edit-modal>
</template>

<script>
import { defineComponent, reactive, ref, onMounted } from 'vue'
import WjCard from '@/components/card/WjCard'
import UserEditModal from '@/views/sys/user/pages/editModal'
import { userApi, authApi } from '@/util/api'
import { COLUMNS } from '@/constant'

export default defineComponent({
  name: 'SysUser',
  components: { UserEditModal, WjCard },
  setup () {
    const loading = ref(false)
    const editModelVisible = ref(false)
    const users = ref([])
    const modalUserId = ref()
    const searchData = reactive({})
    const pagination = reactive({ pageSize: 10, defaultPageSize: 10, showSizeChanger: true, showTotal: (total, range) => `总计：${total} 当前：${range[0]}-${range[1]}` })
    const addModalHandler = () => {
      modalUserId.value = null
      editModelVisible.value = true
    }
    onMounted(() => {
      search({ size: pagination.defaultPageSize, current: 1 })
    })
    const paginationChangeHandler = (config) => {
      pagination.current = config.current
      pagination.pageSize = config.pageSize
      search({ size: config.pageSize, current: config.current, ...searchData })
    }
    const editModalHandler = record => {
      modalUserId.value = record.id
      editModelVisible.value = true
    }
    const search = async (params) => {
      loading.value = true
      const res = await userApi.page(params)
      loading.value = false
      if (res) {
        pagination.total = res.data.total
        users.value = res.data.records
      }
    }
    const searchHandler = () => {
      pagination.current = 1
      search({ size: pagination.pageSize, current: pagination.current, ...searchData })
    }
    const deleteHandler = async id => {
      if (await userApi.deleteById(id)) {
        searchHandler()
      }
    }
    const resetPasswordHandler = async id => {
      await authApi.resetPassword(id)
    }
    const refresh = () => {
      searchHandler()
    }
    return {
      searchData,
      users,
      pagination,
      loading,
      editModelVisible,
      modalUserId,
      refresh,
      searchHandler,
      paginationChangeHandler,
      editModalHandler,
      addModalHandler,
      deleteHandler,
      resetPasswordHandler,
      COLUMNS
    }
  }
})
</script>

<style scoped>

</style>
