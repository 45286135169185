export default {
  USER: [
    {
      title: '用户名',
      align: 'center',
      dataIndex: 'username'
    },
    {
      title: '角色',
      align: 'center',
      dataIndex: 'roleNameList'
    },
    {
      align: 'center',
      title: '有效期',
      dataIndex: 'validPeriod'
    },
    {
      align: 'center',
      title: '状态',
      dataIndex: 'isEnabled'
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center'
    }
  ],
  LOGIN_LOG: [
    {
      title: '用户名',
      align: 'center',
      dataIndex: 'username'
    },
    {
      title: 'IP',
      align: 'center',
      dataIndex: 'ip'
    },
    {
      title: 'IP归属地',
      align: 'center',
      dataIndex: 'ipRegion'
    },
    {
      title: '操作时间',
      align: 'center',
      dataIndex: 'loginDate'
    },
    {
      title: '操作类型',
      align: 'center',
      dataIndex: 'type'
    },
    {
      title: '状态',
      align: 'center',
      dataIndex: 'status'
    },
    {
      title: '失败原因',
      align: 'center',
      dataIndex: 'errorInfo'
    }
  ],
  ROLE: [
    {
      title: '角色名',
      align: 'center',
      dataIndex: 'name'
    },
    {
      title: '备注',
      align: 'center',
      dataIndex: 'remark'
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center'
    }
  ],
  RESOURCE: [
    {
      title: '模块',
      align: 'center',
      dataIndex: 'applicationName'
    },
    {
      title: '资源名称',
      align: 'center',
      dataIndex: 'name'
    },
    {
      title: '请求方法',
      dataIndex: 'method',
      align: 'center'
    },
    {
      title: '路由',
      align: 'center',
      dataIndex: 'url'
    },
    {
      title: '类型',
      align: 'center',
      dataIndex: 'type'
    },
    {
      title: '状态',
      dataIndex: 'isEnabled',
      align: 'center'
    },
    {
      title: '记录日志',
      dataIndex: 'recordLog',
      align: 'center'
    },
    {
      title: '记录请求参数',
      dataIndex: 'recordRequestData',
      align: 'center'
    },
    {
      title: '记录响应数据',
      dataIndex: 'recordResponseData',
      align: 'center'
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center'
    }
  ],
  DICT_TYPE: [
    {
      title: '类型名',
      align: 'center',
      dataIndex: 'name'
    },
    {
      title: '代码',
      align: 'center',
      dataIndex: 'code'
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center'
    }
  ],
  DICT_ITEM: [
    {
      title: '字典项名',
      align: 'center',
      dataIndex: 'name'
    },
    {
      title: '字典项值',
      align: 'center',
      dataIndex: 'code'
    },
    {
      title: '样式',
      align: 'center',
      dataIndex: 'color'
    },
    {
      title: '排序',
      align: 'center',
      dataIndex: 'orderIndex'
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center'
    }
  ],
  LOG: [
    {
      title: '用户名',
      align: 'center',
      dataIndex: 'username'
    },
    {
      title: 'IP',
      align: 'center',
      dataIndex: 'ip'
    },
    {
      title: 'IP归属地',
      align: 'center',
      dataIndex: 'ipRegion'
    },
    {
      title: '模块名',
      align: 'center',
      dataIndex: 'applicationName'
    },
    {
      title: '资源名称',
      align: 'center',
      dataIndex: 'resourceName'
    },
    {
      title: '请求路径',
      align: 'center',
      dataIndex: 'requestUrl'
    },
    {
      title: '请求方法',
      dataIndex: 'requestMethod',
      align: 'center'
    },
    {
      title: '请求时间',
      align: 'center',
      dataIndex: 'requestDate'
    },
    {
      title: '响应时间',
      align: 'center',
      dataIndex: 'responseDate'
    },
    {
      title: '执行时间（ms）',
      align: 'center',
      dataIndex: 'executeMillisecond'
    },
    {
      title: '状态',
      align: 'center',
      dataIndex: 'status'
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center'
    }
  ],
  BLOG: [
    {
      title: '标题',
      align: 'center',
      dataIndex: 'title'
    },
    {
      title: '作者',
      align: 'center',
      dataIndex: 'username'
    },
    {
      title: '标签',
      align: 'center',
      dataIndex: 'blogTagCodeTagList'
    },
    {
      title: '状态',
      align: 'center',
      dataIndex: 'isEnabled'
    },
    {
      title: '置顶',
      align: 'center',
      dataIndex: 'isTop'
    },
    {
      title: '原创',
      align: 'center',
      dataIndex: 'isOriginal'
    },
    {
      title: '浏览量',
      align: 'center',
      dataIndex: 'browseNum'
    },
    {
      title: '创建时间',
      align: 'center',
      dataIndex: 'createDate'
    },
    {
      title: '更新时间',
      align: 'center',
      dataIndex: 'updateDate'
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center'
    }
  ]
}
