/**
 * @param key
 * @param value
 * @param expires 定义有效期。如传入Number，则单位为天，也可传入一个Date对象，表示有效期至Date指定时间。
 */
export const setToken = (key, value) => {
  sessionStorage.setItem(key, value)
}

export const getToken = (key) => {
  return sessionStorage.getItem(key)
}

export const removeToken = (key) => {
  sessionStorage.removeItem(key)
}
