<template>
  <a-button type="primary" :style="{position: 'fixed', right: right, top: '200px', 'z-index': 99999, 'transition-duration': '0.23s'}" @click="handleBtnClick">
    <template #icon><SettingOutlined spin/></template>
  </a-button>
  <a-drawer
    title="布局"
    placement="right"
    v-model:visible="visible"
    width="300px"
  >
    <a-form :model="styleForm" :labelCol="{span: 20}" :wrapperCol="{span: 4}" label-align="left">
      <a-form-item label="固定header" :colon="false">
        <a-switch v-model:checked="styleForm.fixedHeader" @change="handleSwitchFixedHeader"/>
      </a-form-item>
      <a-form-item label="显示tag" :colon="false">
        <a-switch v-model:checked="styleForm.showTag" @change="handleSwitchShowTag"/>
      </a-form-item>
    </a-form>
  </a-drawer>
</template>

<script>
import { defineComponent, ref, watch, reactive } from 'vue'
import { SettingOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import { STORE_STYLE } from '@/constant'

export default defineComponent({
  name: 'WjStyleAffix',
  components: { SettingOutlined },
  setup () {
    const store = useStore()
    const visible = ref(false)
    const right = ref('0')
    const styleForm = reactive({
      fixedHeader: store.state.style.fixedHeader,
      showTag: store.state.style.showTag
    })
    const handleSwitchFixedHeader = (newValue) => {
      store.dispatch(`style/${STORE_STYLE.TOGGLE_FIXED_HEADER}`, newValue)
    }
    const handleSwitchShowTag = (newValue) => {
      store.dispatch(`style/${STORE_STYLE.TOGGLE_SHOW_TAG}`, newValue)
    }
    watch(visible, newValue => {
      if (newValue) {
        right.value = '300px'
      } else {
        right.value = '0'
      }
    })
    const handleBtnClick = () => {
      visible.value = !visible.value
    }
    return {
      visible,
      handleBtnClick,
      right,
      styleForm,
      handleSwitchFixedHeader,
      handleSwitchShowTag
    }
  }
})
</script>
