<template>
  <wj-card>
    <a-form :model="current" :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }" :rules="RULES.SECURITY_POLICY" ref="formRef">
      <a-row :gutter="16">
        <a-col :span="8">
          <a-form-item label="token过期时间（秒）" name="accessTokenExpireSecond">
            <a-input-number v-model:value="current.accessTokenExpireSecond"/>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="刷新token过期时间（秒）" name="refreshTokenExpireSecond">
            <a-input-number v-model:value="current.refreshTokenExpireSecond"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="8">
          <a-form-item label="更新刷新token" name="updateRefreshToken">
            <a-switch v-model:checked="current.updateRefreshToken" checked-children="启用" un-checked-children="禁用"/>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="验证IP" name="verifyIp">
            <a-switch v-model:checked="current.verifyIp" checked-children="启用" un-checked-children="禁用"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="8">
          <a-form-item label="密码不能包含空格" name="whitespaceRule">
            <a-switch v-model:checked="current.whitespaceRule" checked-children="启用" un-checked-children="禁用"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="8">
          <a-form-item label="密码长度验证" name="lengthRule">
            <a-switch v-model:checked="current.lengthRule" checked-children="启用" un-checked-children="禁用"/>
          </a-form-item>
        </a-col>
        <a-col :span="8" v-if="current.lengthRule">
          <a-form-item label="密码长度最小值" name="lengthRuleMin">
            <a-input-number v-model:value="current.lengthRuleMin"/>
          </a-form-item>
        </a-col>
        <a-col :span="8" v-if="current.lengthRule">
          <a-form-item label="密码长度最大值" name="lengthRuleMax">
            <a-input-number v-model:value="current.lengthRuleMax"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="8">
          <a-form-item label="密码大写字母验证" name="characterRuleUpperCase">
            <a-switch v-model:checked="current.characterRuleUpperCase" checked-children="启用" un-checked-children="禁用"/>
          </a-form-item>
        </a-col>
        <a-col :span="8" v-if="current.characterRuleUpperCase">
          <a-form-item label="大写字母验证数量" name="characterRuleUpperCaseNum">
            <a-input-number v-model:value="current.characterRuleUpperCaseNum"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="8">
          <a-form-item label="密码小写字母验证" name="characterRuleLowerCase">
            <a-switch v-model:checked="current.characterRuleLowerCase" checked-children="启用" un-checked-children="禁用"/>
          </a-form-item>
        </a-col>
        <a-col :span="8" v-if="current.characterRuleLowerCase">
          <a-form-item label="小写字母验证数量" name="characterRuleLowerCaseNum">
            <a-input-number v-model:value="current.characterRuleLowerCaseNum"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="8">
          <a-form-item label="密码英文字母验证" name="characterAlphabetical">
            <a-switch v-model:checked="current.characterAlphabetical" checked-children="启用" un-checked-children="禁用"/>
          </a-form-item>
        </a-col>
        <a-col :span="8" v-if="current.characterAlphabetical">
          <a-form-item label="英文字母验证数量" name="characterAlphabeticalNum">
            <a-input-number v-model:value="current.characterAlphabeticalNum"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="8">
          <a-form-item label="密码数字验证" name="characterDigit">
            <a-switch v-model:checked="current.characterDigit" checked-children="启用" un-checked-children="禁用"/>
          </a-form-item>
        </a-col>
        <a-col :span="8" v-if="current.characterDigit">
          <a-form-item label="数字验证数量" name="characterDigitNum">
            <a-input-number v-model:value="current.characterDigitNum"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="8">
          <a-form-item label="密码特殊字符验证" name="characterRuleSpecial">
            <a-switch v-model:checked="current.characterRuleSpecial" checked-children="启用" un-checked-children="禁用"/>
          </a-form-item>
        </a-col>
        <a-col :span="8" v-if="current.characterRuleSpecial">
          <a-form-item label="特殊字符验证数量" name="characterRuleSpecialNum">
            <a-input-number v-model:value="current.characterRuleSpecialNum"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="8">
          <a-form-item label="密码连续英文字母验证" name="illegalSequenceRuleAlphabetical">
            <a-switch v-model:checked="current.illegalSequenceRuleAlphabetical" checked-children="启用" un-checked-children="禁用"/>
          </a-form-item>
        </a-col>
        <a-col :span="8" v-if="current.illegalSequenceRuleAlphabetical">
          <a-form-item label="连续英文字母验证数量" name="illegalSequenceRuleAlphabeticalNum">
            <a-input-number v-model:value="current.illegalSequenceRuleAlphabeticalNum"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="8">
          <a-form-item label="密码连续数字验证" name="illegalSequenceRuleNumerical">
            <a-switch v-model:checked="current.illegalSequenceRuleNumerical" checked-children="启用" un-checked-children="禁用"/>
          </a-form-item>
        </a-col>
        <a-col :span="8" v-if="current.illegalSequenceRuleNumerical">
          <a-form-item label="连续数字验证数量" name="illegalSequenceRuleNumericalNum">
            <a-input-number v-model:value="current.illegalSequenceRuleNumericalNum"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="8">
          <a-form-item label="密码连续键盘字母验证" name="illegalSequenceRuleQwerty">
            <a-switch v-model:checked="current.illegalSequenceRuleQwerty" checked-children="启用" un-checked-children="禁用"/>
          </a-form-item>
        </a-col>
        <a-col :span="8" v-if="current.illegalSequenceRuleQwerty">
          <a-form-item label="连续键盘字母验证数量" name="illegalSequenceRuleQwertyNum">
            <a-input-number v-model:value="current.illegalSequenceRuleQwertyNum"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="16">
          <a-form-item label="密码强度不符合规则提示" name="passwordStrengthPrompt" :label-col="{ span: 5 }" show-count>
            <a-textarea v-model:value="current.passwordStrengthPrompt" auto-size/>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <div style="text-align: center;width: 100%">
      <a-button type="primary" @click="handleSubmit" v-auth:security-policy-update>提交</a-button>
    </div>
  </wj-card>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { authApi } from '@/util/api'
import ObjectUtil from '@/util/ObjectUtil'
import { RULES } from '@/constant'

export default defineComponent({
  name: 'SecurityPolicy',
  setup () {
    const formRef = ref()
    const current = reactive({})
    const handleSubmit = () => {
      formRef.value.validate().then(async () => await authApi.updateSecurityPolicy(current))
    }
    onMounted(async () => {
      const res = await authApi.getSecurityPolicy()
      if (res) {
        ObjectUtil.clearAttributesAndAssign(current, res.data)
      }
    })
    return {
      current,
      handleSubmit,
      RULES,
      formRef
    }
  }
})
</script>
