<template>
  <wj-card>
    <a-form
      layout="horizontal"
      :model="current"
      :rules="RULES.BLOG"
      ref="formRef"
    >
      <a-form-item label="标题" name="title">
        <a-input v-model:value="current.title"></a-input>
      </a-form-item>
      <a-form-item label="简介" name="introduction">
        <a-textarea v-model:value="current.introduction" autoSize/>
      </a-form-item>
      <a-form-item label="标签" name="blogTagCodeList">
        <wj-dict-select :type-code="DICT_TYPE.BLOG_TAG" mode="multiple" v-model:value="current.blogTagCodeList"/>
      </a-form-item>
      <a-row :gutter="16">
        <a-col :span="4">
          <a-form-item label="启用" name="isEnabled">
            <a-switch checked-children="启用" un-checked-children="禁用" v-model:checked="current.isEnabled" />
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item label="置顶" name="isTop">
            <a-switch checked-children="是" un-checked-children="否" v-model:checked="current.isTop" />
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item label="原创" name="isOriginal">
            <a-switch checked-children="是" un-checked-children="否" v-model:checked="current.isOriginal" />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <v-md-editor
      v-model="current.content"
      height="500px"
      @copy-code-success="handleCopyCodeSuccess"
      :disabled-menus="[]"
      @upload-image="handleUploadImage"
    />
    <div style="width: 100%;text-align: center;margin-top: 10px">
      <a-button type="primary" v-if="!current.id" v-auth:sys-blog-insert @click="handleInsert" :disabled="btnDisabled">提交</a-button>
      <a-button type="primary" v-if="current.id" v-auth:sys-blog-update @click="handleUpdate" :disabled="btnDisabled">更新</a-button>
    </div>
  </wj-card>
</template>

<script>
import { defineComponent, reactive, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import WjCard from '@/components/card/WjCard'
import { blogApi, fileApi } from '@/util/api'
import ObjectUtil from '@/util/ObjectUtil'
import { RULES, DICT_TYPE, STORE_OTHER } from '@/constant'
import { message } from 'ant-design-vue'
import WjDictSelect from '@/components/select/WjDictSelect'

export default defineComponent({
  name: 'BlogEdit',
  components: { WjDictSelect, WjCard },
  setup () {
    const route = useRoute()
    const store = useStore()
    const current = reactive({})
    const formRef = ref()
    const btnDisabled = ref(false)
    onMounted(async () => {
      const id = route.params.id
      if (id) {
        const res = await blogApi.selectById(id)
        if (res) {
          ObjectUtil.clearAttributesAndAssign(current, res.data)
          btnDisabled.value = false
        } else {
          ObjectUtil.clearAttributesAndAssign(current, { isTop: false, isEnabled: true, isOriginal: true })
        }
      } else {
        ObjectUtil.clearAttributesAndAssign(current, { isTop: false, isEnabled: true, isOriginal: true })
        btnDisabled.value = false
      }
    })
    const handleCopyCodeSuccess = value => {
      console.log(value)
    }
    const check = callback => {
      formRef.value.validate().then(() => {
        if (current.content) {
          callback()
        } else {
          message.error('文章内容不能为空')
        }
      })
    }
    const after = () => {
      btnDisabled.value = true
      store.dispatch(`other/${STORE_OTHER.REFRESH_BLOG}`, true)
    }
    const handleInsert = () => {
      check(async () => {
        const res = await blogApi.insert(current)
        if (res) {
          after()
        }
      })
    }
    const handleUpdate = async () => {
      check(async () => {
        const res = await blogApi.update(current)
        if (res) {
          after()
        }
      })
    }
    const handleUploadImage = (event, insertImage, files) => {
      if (files && files.length > 0) {
        files.forEach(async file => {
          const data = new FormData()
          data.append('file', file)
          data.append('isTemp', 'true')
          const res = await fileApi.upload(data)
          if (res) {
            if (!current.blogFileList) {
              current.blogFileList = [{ fileId: res.data.fileId, fileUrl: res.data.fileUrl }]
            } else {
              current.blogFileList.push({ fileId: res.data.fileId, fileUrl: res.data.fileUrl })
            }
            insertImage({
              url: res.data.fileUrl
              // desc: '七龙珠'
              // width: 'auto',
              // height: 'auto'
            })
          }
        })
      }
    }
    return {
      RULES,
      current,
      formRef,
      handleInsert,
      handleUpdate,
      handleCopyCodeSuccess,
      handleUploadImage,
      btnDisabled,
      DICT_TYPE
    }
  }
})
</script>

<style scoped>

</style>
