const stringNotBlank = {
  required: true,
  message: '不能为空',
  trigger: ['change', 'blur'],
  whitespace: true
}
const arrayNotBlank = {
  required: true,
  message: '不能为空',
  trigger: ['change', 'blur'],
  type: 'array'
}
const booleanNotBlank = {
  required: true,
  message: '不能为空',
  trigger: ['change', 'blur'],
  type: 'boolean'
}
const numberNotBlank = {
  required: true,
  message: '不能为空',
  trigger: ['change', 'blur'],
  type: 'number'
}

export default {
  LOGIN: {
    username: [stringNotBlank],
    password: [stringNotBlank]
  },
  USER: {
    username: [stringNotBlank],
    roleIdList: [arrayNotBlank],
    isEnabled: [booleanNotBlank]
  },
  RESOURCE: {
    name: [stringNotBlank],
    url: [stringNotBlank],
    method: [stringNotBlank],
    applicationName: [stringNotBlank],
    type: [stringNotBlank],
    isEnabled: [booleanNotBlank]
  },
  ROLE: {
    name: [stringNotBlank]
  },
  DICT_TYPE: {
    name: [stringNotBlank],
    code: [stringNotBlank]
  },
  DICT_ITEM: {
    name: [stringNotBlank],
    code: [stringNotBlank],
    orderIndex: [numberNotBlank]
  },
  USER_INFO: {
    avatar: [stringNotBlank],
    email: [stringNotBlank]
  },
  SECURITY_POLICY: {
    accessTokenExpireSecond: [
      numberNotBlank,
      { min: 1, message: 'token过期时间最小为1', trigger: ['change', 'blur'], type: 'number' }
    ],
    refreshTokenExpireSecond: [
      numberNotBlank,
      { min: 1, message: '刷新token过期时间最小为1', trigger: ['change', 'blur'], type: 'number' }
    ],
    updateRefreshToken: [booleanNotBlank],
    verifyIp: [booleanNotBlank],
    whitespaceRule: [booleanNotBlank],
    lengthRule: [booleanNotBlank],
    lengthRuleMin: [
      numberNotBlank,
      { min: 1, message: '密码最小长度最小为1', trigger: ['change', 'blur'], type: 'number' }
    ],
    lengthRuleMax: [
      numberNotBlank,
      { min: 1, message: '密码最大长度最小为1', trigger: ['change', 'blur'], type: 'number' }
    ],
    characterRuleUpperCase: [booleanNotBlank],
    characterRuleUpperCaseNum: [
      numberNotBlank,
      { min: 1, message: '大写字母验证数量不能小于1', trigger: ['change', 'blur'], type: 'number' }
    ],
    characterRuleLowerCase: [booleanNotBlank],
    characterRuleLowerCaseNum: [
      numberNotBlank,
      { min: 1, message: '小写字母验证数量不能小于1', trigger: ['change', 'blur'], type: 'number' }
    ],
    characterAlphabetical: [booleanNotBlank],
    characterAlphabeticalNum: [
      numberNotBlank,
      { min: 1, message: '英文字母验证数量不能小于1', trigger: ['change', 'blur'], type: 'number' }
    ],
    characterDigit: [booleanNotBlank],
    characterDigitNum: [
      numberNotBlank,
      { min: 1, message: '数字验证数量不能小于1', trigger: ['change', 'blur'], type: 'number' }
    ],
    characterRuleSpecial: [booleanNotBlank],
    characterRuleSpecialNum: [
      numberNotBlank,
      { min: 1, message: '特殊字符验证数量不能小于1', trigger: ['change', 'blur'], type: 'number' }
    ],
    illegalSequenceRuleAlphabetical: [booleanNotBlank],
    illegalSequenceRuleAlphabeticalNum: [
      numberNotBlank,
      { min: 3, message: '连续英文字母验证数量不能小于3', trigger: ['change', 'blur'], type: 'number' }
    ],
    illegalSequenceRuleNumerical: [booleanNotBlank],
    illegalSequenceRuleNumericalNum: [
      numberNotBlank,
      { min: 3, message: '连续数字验证数量不能小于3', trigger: ['change', 'blur'], type: 'number' }
    ],
    illegalSequenceRuleQwerty: [booleanNotBlank],
    illegalSequenceRuleQwertyNum: [
      numberNotBlank,
      { min: 3, message: '连续键盘字母验证数量不能小于3', trigger: ['change', 'blur'], type: 'number' }
    ],
    passwordStrengthPrompt: [stringNotBlank]
  },
  SYS_PARAM: {
    fileRootPath: [stringNotBlank],
    fileRootRequestUrl: [stringNotBlank],
    footer: [stringNotBlank],
    defaultPassword: [stringNotBlank],
    tempFileExpireHour: [
      numberNotBlank,
      { min: 2, message: '临时文件过期时间不能小于2小时', trigger: ['change', 'blur'], type: 'number' }
    ]
  },
  BLOG: {
    title: [stringNotBlank],
    introduction: [stringNotBlank],
    isEnabled: [booleanNotBlank],
    isTop: [booleanNotBlank],
    isOriginal: [booleanNotBlank],
    blogTagCodeList: [arrayNotBlank]
  }
}
