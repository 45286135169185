<template>
  <wj-card>
    <a-tabs defaultActiveKey="1" tabPosition="left">
      <a-tab-pane key="1" tab="基本信息" style="text-align: center">
        <a-form :model="user" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }" :rules="RULES.USER_INFO" ref="userInfoRef">
          <a-upload
              name="avatar"
              list-type="picture-card"
              :show-upload-list="false"
              :before-upload="beforeAvatarUpload"
              @change="handleAvatarChange"
              :custom-request="customRequest"
              accept="image/*"
          >
            <img v-if="user.avatar" :src="user.avatar" alt="avatar" style="width: 100px;height: 100px;"/>
            <div v-else>
              <plus-outlined />
              <div class="ant-upload-text">上传头像</div>
            </div>
          </a-upload>
<!--          <a-form-item label="头像" name="avatar">-->
<!--            <a-input v-model:value="user.avatar"/>-->
<!--          </a-form-item>-->
          <a-form-item label="用户名" name="username">
            <a-input v-model:value="user.username" disabled/>
          </a-form-item>
          <a-form-item label="邮箱" name="email">
            <a-input v-model:value="user.email"/>
          </a-form-item>
        </a-form>
        <a-button type="primary" @click="handleUserInfoSubmit">提交</a-button>
      </a-tab-pane>
      <a-tab-pane key="2" tab="修改密码" style="text-align: center">
        <a-form :model="passwordForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }" ref="passwordRef" :rules="passwordRules">
          <a-form-item label="原密码" name="password">
            <a-input-password v-model:value="passwordForm.password"/>
          </a-form-item>
          <a-form-item label="新密码" name="newPassword">
            <a-input-password v-model:value="passwordForm.newPassword"/>
          </a-form-item>
          <a-form-item label="确认密码" name="againPassword">
            <a-input-password v-model:value="passwordForm.againPassword"/>
          </a-form-item>
        </a-form>
        <a-button type="primary" @click="handlePasswordSubmit">修改密码</a-button>
      </a-tab-pane>
    </a-tabs>
  </wj-card>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { userApi, authApi } from '@/util/api'
import ObjectUtil from '@/util/ObjectUtil'
import { RULES } from '@/constant'
import { PlusOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
function getBase64 (img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}
export default defineComponent({
  name: 'UserInfo',
  components: { PlusOutlined },
  setup () {
    const userInfoRef = ref()
    const passwordRef = ref()
    const user = reactive({})
    const passwordForm = reactive({})
    const handleUserInfoSubmit = () => {
      userInfoRef.value.validate().then(async () => {
        await userApi.updateUserInfo(user)
      })
    }
    const handlePasswordSubmit = () => {
      passwordRef.value.validate().then(async () => {
        const passwordChangeData = {
          password: passwordForm.password,
          newPassword: passwordForm.newPassword,
          againPassword: passwordForm.againPassword
        }
        const res = await authApi.passwordChange(passwordChangeData)
        if (res) {
          ObjectUtil.clearAttributes(passwordForm)
        }
      })
    }
    onMounted(async () => {
      const res = await userApi.getUserInfo()
      if (res) {
        ObjectUtil.clearAttributesAndAssign(user, res.data)
      }
    })
    const validateNewPassword = async (rule, value) => {
      if (value === '') {
        return Promise.reject(new Error('请输入新密码'))
      } else {
        if (passwordForm.newPassword !== '') {
          passwordRef.value.validateFields('againPassword')
        }
        return Promise.resolve()
      }
    }
    const validateAgainPassword = async (rule, value) => {
      if (value === '') {
        return Promise.reject(new Error('新密码和确认密码不相同'))
      } else if (value !== passwordForm.newPassword) {
        return Promise.reject(new Error('新密码和确认密码不相同'))
      } else {
        return Promise.resolve()
      }
    }
    const passwordRules = {
      password: [
        {
          required: true,
          message: '请输入原密码',
          trigger: ['change', 'blur'],
          whitespace: true
        }
      ],
      newPassword: [
        {
          required: true,
          validator: validateNewPassword,
          trigger: ['change', 'blur']
        }
      ],
      againPassword: [
        {
          required: true,
          validator: validateAgainPassword,
          trigger: ['change', 'blur']
        }
      ]
    }
    const handleAvatarChange = info => {
      getBase64(info.file.originFileObj, base64Url => {
        user.avatar = base64Url
      })
    }
    const customRequest = () => {
      return false
    }
    const beforeAvatarUpload = file => {
      if (file.size / 1024 <= 300) {
        return true
      } else {
        message.error('头像不能大于300KB')
        return Promise.reject(new Error('头像不能大于300KB'))
      }
    }
    return {
      user,
      passwordForm,
      handleUserInfoSubmit,
      handlePasswordSubmit,
      RULES,
      userInfoRef,
      passwordRef,
      passwordRules,
      handleAvatarChange,
      customRequest,
      beforeAvatarUpload
    }
  }
})
</script>

<style scoped>
.ant-upload-picture-card-wrapper{
  width: auto !important;
}
</style>
