import axios from 'axios'
import { HTTP_METHOD, REQUEST_HEADER, STORE_USER } from '@/constant'
import * as storage from '@/util/cookie'
import { message, Modal } from 'ant-design-vue'
import store from '../store'
import { h } from 'vue'

const http = axios.create({
  timeout: 1000 * 20,
  baseURL: process.env.VUE_APP_BASE_URL,
  withCredentials: false,
  timeoutErrorMessage: '连接超时'
  // headers: {
  //   'Content-Type': 'application/json'
  // }
})

http.interceptors.request.use(config => {
  const accessToken = storage.getToken(REQUEST_HEADER.ACCESS_TOKEN)
  const refreshToken = storage.getToken(REQUEST_HEADER.REFRESH_TOKEN)
  if (accessToken && refreshToken) {
    config.headers[REQUEST_HEADER.ACCESS_TOKEN] = accessToken
    config.headers[REQUEST_HEADER.REFRESH_TOKEN] = refreshToken
  }
  return config
}, error => {
  return Promise.reject(error)
})

http.interceptors.response.use(response => {
  const accessToken = response.headers[REQUEST_HEADER.ACCESS_TOKEN]
  const refreshToken = response.headers[REQUEST_HEADER.REFRESH_TOKEN]
  if (accessToken && refreshToken) {
    storage.setToken(REQUEST_HEADER.ACCESS_TOKEN, accessToken)
    storage.setToken(REQUEST_HEADER.REFRESH_TOKEN, refreshToken)
  }
  return response
}, error => {
  return Promise.reject(error)
})

/* export function request (options) {
  const defaultOptions = {
    method: 'GET',
    headers: {},
    success: (res) => { message.success(res.msg) },
    fail: (res) => {
      Modal.error({
        width: 600,
        centered: true,
        title: '异常提示',
        content: res.msg
      })
    },
    error: (error) => {
      console.error(error)
      Modal.error({
        width: 600,
        centered: true,
        title: '异常提示',
        content: error.message
      })
    },
    always: () => {}
  }
  const currentOptions = Object.assign(defaultOptions, options)
  http.request(currentOptions).then(response => {
    currentOptions.always()
    const res = response.data
    if (res.code === 200) {
      currentOptions.success(res)
    } else if (res.code === 401) {
      Modal.error({
        width: 600,
        centered: true,
        title: '异常提示',
        content: res.msg,
        onOk: () => router.push('/login')
      })
    } else {
      currentOptions.fail(res)
    }
  }).catch(error => {
    currentOptions.always()
    currentOptions.error(error)
  })
} */

export async function requestAsync (options) {
  const defaultOptions = {
    method: HTTP_METHOD.GET,
    errorPrompt: true,
    successPrompt: false,
    loading: false
  }
  const currentOptions = Object.assign(defaultOptions, options)
  try {
    if (options.loading === true) {
      message.loading('loading', 0)
    }
    const response = await http.request(options)
    if (options.loading === true) {
      message.destroy()
    }
    const res = response.data
    if (res.code === 200) {
      if (currentOptions.successPrompt) {
        message.success(res.msg)
      }
      return res
    } else if (res.code === 401) {
      code401Handler(res.msg)
    } else {
      if (currentOptions.errorPrompt === true) {
        codeOtherHandler(res.msg)
      }
    }
    return false
  } catch (e) {
    message.destroy()
    if (currentOptions.errorPrompt === true) {
      errorHandler(e.message)
    }
    return false
  }
}

const code401Handler = msg => {
  Modal.error({
    width: 600,
    centered: true,
    title: '异常提示',
    content: h('pre', { style: { fontWeight: 'bold' } }, msg),
    onOk: () => store.commit(`user/${STORE_USER.LOGOUT}`)
  })
}

const codeOtherHandler = msg => {
  Modal.warn({
    width: 600,
    centered: true,
    title: '业务提示',
    content: h('pre', { style: { fontWeight: 'bold' } }, msg)
  })
}

const errorHandler = msg => {
  Modal.error({
    width: 600,
    centered: true,
    title: '错误提示',
    content: h('pre', { style: { fontWeight: 'bold' } }, msg)
  })
}
