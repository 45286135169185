<template>
  <a-layout-footer :style="{ textAlign: 'center', width: '100%'}">
    <div  v-html="footer"></div>
  </a-layout-footer>
</template>

<script>
import { defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'LayoutFooter',
  setup () {
    const store = useStore()
    const footer = store.state.user.param.footer
    return {
      footer
    }
  }

})
</script>
