const ObjectUtil = {
  clearAttributes: (obj) => {
    for (const key in obj) {
      delete obj[key]
    }
  },
  assign: (target, source) => {
    for (const key in source) {
      target[key] = source[key]
    }
  },
  clearAttributesAndAssign: (target, source) => {
    ObjectUtil.clearAttributes(target)
    ObjectUtil.assign(target, source)
  }
}

export default ObjectUtil
