<template>
  <a-modal
    :visible="editModelVisible"
    :title="currentLog.resourceName ? `日志(${currentLog.resourceName})` : '日志'"
    :maskClosable="true"
    @cancel="cancelHandler"
    width="60%"
  >
    <template v-slot:footer>
      <a-button @click="cancelHandler">退出</a-button>
    </template>
    <div style="background-color: #ececec; padding: 20px">
      <a-row :gutter="16">
        <a-col :span="12">
          <a-card title="请求参数" :bordered="false">
            <json-viewer v-if='currentLog.requestData' :value="JSON.parse(currentLog.requestData)" copyable/>
            <json-viewer v-else :value="{}" boxed/>
          </a-card>
        </a-col>
        <a-col :span="12">
          <a-card title="响应数据" :bordered="false">
            <json-viewer v-if='currentLog.responseData' :value="JSON.parse(currentLog.responseData)" copyable :expand-depth=2 />
            <json-viewer v-else :value="{}" boxed/>
          </a-card>
        </a-col>
      </a-row>
    </div>
  </a-modal>
</template>

<script>
import { reactive, watch } from 'vue'
import ObjectUtil from '@/util/ObjectUtil'
import { logApi } from '@/util/api'

export default {
  name: 'log-edit-modal',
  props: {
    logId: {
      type: String,
      require: false
    },
    editModelVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:editModelVisible'],
  setup (props, context) {
    // const { user } = toRefs(props)
    const currentLog = reactive({})
    watch(() => props.logId, async (newValue, oldValue) => {
      if (newValue) {
        const res = await logApi.getById(newValue)
        if (res) {
          ObjectUtil.clearAttributesAndAssign(currentLog, res.data)
        }
      } else {
        ObjectUtil.clearAttributes(currentLog)
      }
    })
    const cancelHandler = () => {
      context.emit('update:editModelVisible', false)
    }
    return {
      cancelHandler,
      currentLog
    }
  }
}
</script>

<style scoped>

</style>
