<template>
  <a-modal
    :visible="modalVisible"
    title="资源配置"
    @cancel="handleCancel"
    @ok="handleOk"
    :maskClosable="false"
    width="800px"
  >
    <a-transfer
      show-search
      :data-source="resourceList"
      :titles="['Source', 'Target']"
      :render="item => item.title"
      :target-keys="targetKeys"
      @change="handleChange"
      :list-style="{ width: '350px', height: '600px'}"
    />
  </a-modal>
</template>

<script>
import { watch, ref } from 'vue'
import { resourceApi } from '@/util/api'
export default {
  name: 'setResourceModal',
  props: {
    modalVisible: {
      type: Boolean,
      required: false,
      default: false
    },
    resourceIdList: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  emits: ['update:modalVisible', 'setResourceIdList'],
  setup (props, context) {
    const resourceList = ref([])
    const targetKeys = ref([])
    const handleOk = () => {
      context.emit('setResourceIdList', targetKeys)
      context.emit('update:modalVisible', false)
    }
    const handleCancel = () => {
      targetKeys.value = JSON.parse(JSON.stringify(props.resourceIdList))
      context.emit('update:modalVisible', false)
    }
    watch(() => props.resourceIdList, async newValue => {
      if (newValue) {
        if (!resourceList.value || resourceList.value.length === 0) {
          const res = await resourceApi.getSetResourceList()
          if (res) {
            res.data.forEach(item => {
              item.key = item.id
              item.title = item.name
            })
            resourceList.value = res.data
          }
        }
        targetKeys.value = JSON.parse(JSON.stringify(newValue))
      } else {
        targetKeys.value = []
      }
    })
    const handleChange = (keys, direction, moveKeys) => {
      targetKeys.value = keys
    }
    return {
      resourceList,
      targetKeys,
      handleOk,
      handleCancel,
      handleChange
    }
  }
}
</script>

<style scoped>

</style>
