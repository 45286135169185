import { requestAsync } from '@/util/http'
import { HTTP_METHOD } from '@/constant'

export const sysApi = {
  updateSysParam: async data => await requestAsync({ url: '/wj-fast-sys/sys-param', loading: true, method: HTTP_METHOD.PUT, data, successPrompt: true }),
  getSysParam: async () => await requestAsync({ url: '/wj-fast-sys/sys-param', loading: true })
}

export const userApi = {
  initUser: async () => await requestAsync({ url: '/wj-fast-sys/auth/initUser' }),
  page: async params => {
    return await requestAsync({ url: '/wj-fast-sys/sys-user/page', params })
  },
  deleteById: async id => {
    return await requestAsync({ url: `/wj-fast-sys/sys-user/${id}`, method: HTTP_METHOD.DELETE, loading: true, successPrompt: true })
  },
  getById: async id => {
    return await requestAsync({ url: `/wj-fast-sys/sys-user/id/${id}`, loading: true })
  },
  update: async data => {
    return await requestAsync({ url: '/wj-fast-sys/sys-user', method: HTTP_METHOD.PUT, data })
  },
  insert: async data => {
    return await requestAsync({ url: '/wj-fast-sys/sys-user', method: HTTP_METHOD.POST, data })
  },
  getUserInfo: async () => await requestAsync({ url: '/wj-fast-sys/sys-user/userInfo', loading: true }),
  updateUserInfo: async data => await requestAsync({ url: '/wj-fast-sys/sys-user/userInfo', loading: true, method: HTTP_METHOD.PUT, data, successPrompt: true })
}

export const menuApi = {
  getById: async id => await requestAsync({ url: `/wj-fast-sys/sys-menu/id/${id}`, loading: true }),
  deleteById: async id => await requestAsync({ url: `/wj-fast-sys/sys-menu/${id}`, method: HTTP_METHOD.DELETE, loading: true, successPrompt: true }),
  insert: async data => await requestAsync({ url: '/wj-fast-sys/sys-menu', data, method: HTTP_METHOD.POST, loading: true, successPrompt: true }),
  update: async data => await requestAsync({ url: '/wj-fast-sys/sys-menu', data, method: HTTP_METHOD.PUT, loading: true, successPrompt: true }),
  getMenuTree: async () => {
    return await requestAsync({ url: '/wj-fast-sys/sys-menu', loading: true })
  }
}

export const roleApi = {
  page: async params => {
    return await requestAsync({ url: '/wj-fast-sys/sys-role/page', params })
  },
  list: async () => {
    return await requestAsync({ url: '/wj-fast-sys/sys-role/list' })
  },
  getById: async id => {
    return await requestAsync({ url: `/wj-fast-sys/sys-role/id/${id}`, loading: true })
  },
  deleteById: async id => {
    return await requestAsync({ url: `/wj-fast-sys/sys-role/${id}`, method: HTTP_METHOD.DELETE, loading: true, successPrompt: true })
  },
  update: async data => {
    return await requestAsync({ url: '/wj-fast-sys/sys-role', method: HTTP_METHOD.PUT, data, loading: true, successPrompt: true })
  },
  insert: async data => {
    return await requestAsync({ url: '/wj-fast-sys/sys-role', method: HTTP_METHOD.POST, data, loading: true, successPrompt: true })
  }
}

export const resourceApi = {
  page: async params => {
    return await requestAsync({ url: '/wj-fast-sys/sys-resource/page', params })
  },
  deleteById: async id => {
    return await requestAsync({ url: `/wj-fast-sys/sys-resource/${id}`, method: HTTP_METHOD.DELETE, loading: true, successPrompt: true })
  },
  getById: async id => await requestAsync({ url: `/wj-fast-sys/sys-resource/id/${id}`, loading: true }),
  update: async data => {
    return await requestAsync({ url: '/wj-fast-sys/sys-resource', method: HTTP_METHOD.PUT, data, successPrompt: true, loading: true })
  },
  insert: async data => {
    return await requestAsync({ url: '/wj-fast-sys/sys-resource', method: HTTP_METHOD.POST, data, successPrompt: true, loading: true })
  },
  getSetResourceList: async () => await requestAsync({ url: '/wj-fast-sys/sys-resource/setResourceList' })
}

export const logApi = {
  page: async params => {
    return await requestAsync({ url: '/wj-fast-sys/log/page', params })
  },
  getById: async id => {
    return await requestAsync({ url: `/wj-fast-sys/log/id/${id}`, loading: true })
  }
}

export const dictApi = {
  typeList: async () => await requestAsync({ url: '/wj-fast-sys/sys-dict/typeList' }),
  itemList: async typeCode => await requestAsync({ url: `/wj-fast-sys/sys-dict/itemList/${typeCode}` }),
  deleteType: async id => await requestAsync({ url: `/wj-fast-sys/sys-dict/type/${id}`, loading: true, method: HTTP_METHOD.DELETE, successPrompt: true }),
  deleteItem: async id => await requestAsync({ url: `/wj-fast-sys/sys-dict/item/${id}`, loading: true, method: HTTP_METHOD.DELETE, successPrompt: true }),
  getTypeById: async id => await requestAsync({ url: `/wj-fast-sys/sys-dict/type/${id}`, loading: true }),
  getItemById: async id => await requestAsync({ url: `/wj-fast-sys/sys-dict/item/${id}`, loading: true }),
  updateType: async data => await requestAsync({ url: '/wj-fast-sys/sys-dict/type', loading: true, method: HTTP_METHOD.PUT, successPrompt: true, data }),
  updateItem: async data => await requestAsync({ url: '/wj-fast-sys/sys-dict/item', loading: true, method: HTTP_METHOD.PUT, successPrompt: true, data }),
  insertType: async data => await requestAsync({ url: '/wj-fast-sys/sys-dict/type', loading: true, method: HTTP_METHOD.POST, successPrompt: true, data }),
  insertItem: async data => await requestAsync({ url: '/wj-fast-sys/sys-dict/item', loading: true, method: HTTP_METHOD.POST, successPrompt: true, data })
}

export const styleApi = {
  update: async data => await requestAsync({ url: '/wj-fast-sys/sys-style', method: HTTP_METHOD.PUT, data })
}

export const loginLogApi = {
  page: async params => await requestAsync({ url: '/wj-fast-sys/sys-login-log', params })
}

export const authApi = {
  login: async data => await requestAsync({ url: '/wj-fast-auth/oauth/token', method: HTTP_METHOD.POST, data: data }),
  logout: async () => await requestAsync({ url: '/wj-fast-auth/oauth', method: HTTP_METHOD.DELETE, loading: true }),
  getSecurityPolicy: async () => await requestAsync({ url: '/wj-fast-auth/security-policy', loading: true }),
  updateSecurityPolicy: async data => await requestAsync({ url: '/wj-fast-auth/security-policy', loading: true, method: HTTP_METHOD.PUT, successPrompt: true, data }),
  passwordChange: async data => await requestAsync({ url: '/wj-fast-auth/oauth/updatePassword', method: HTTP_METHOD.PUT, data: data, successPrompt: true, loading: true }),
  passwordChangeByLogin: async data => await requestAsync({ url: '/wj-fast-auth/oauth/updatePasswordByLogin', method: HTTP_METHOD.PUT, data: data, successPrompt: true, loading: true }),
  resetPassword: async id => await requestAsync({ url: `/wj-fast-auth/oauth/resetPassword/${id}`, method: HTTP_METHOD.PUT, successPrompt: true, loading: true })
}

export const blogApi = {
  page: async params => await requestAsync({ url: '/wj-fast-blog/sys-blog/page', params }),
  selectById: async id => await requestAsync({ url: `/wj-fast-blog/sys-blog/id/${id}`, loading: true }),
  insert: async data => await requestAsync({ url: '/wj-fast-blog/sys-blog', method: HTTP_METHOD.POST, loading: true, successPrompt: true, data }),
  update: async data => await requestAsync({ url: '/wj-fast-blog/sys-blog', method: HTTP_METHOD.PUT, loading: true, successPrompt: true, data }),
  deleteById: async id => {
    return await requestAsync({ url: `/wj-fast-blog/sys-blog/id/${id}`, method: HTTP_METHOD.DELETE, loading: true, successPrompt: true })
  }
}

export const fileApi = {
  upload: async data => await requestAsync({ url: '/wj-fast-file/file', method: HTTP_METHOD.POST, data, loading: true })
}
