<template>
  <wj-card>
    <a-form
      layout="inline"
      :model="searchData"
    >
      <a-form-item label="标题">
        <a-input v-model:value="searchData.title"></a-input>
      </a-form-item>
      <a-form-item label="作者">
        <a-input v-model:value="searchData.username"></a-input>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="handleSearch">
          搜索
        </a-button>
        <a-button type="primary" @click="handleInsert" v-auth:sys-blog-insert>
          新增
        </a-button>
      </a-form-item>
    </a-form>
  </wj-card>
  <wj-card>
    <a-table size="small" :columns="COLUMNS.BLOG" :data-source="list" bordered :pagination="pagination" @change="paginationChangeHandler" :loading="loading" :rowKey="record=>record.id">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'isEnabled'">
          <a-tag :color="record.isEnabledTag.color">{{record.isEnabledTag.name}}</a-tag>
        </template>
        <template v-if="column.dataIndex === 'isTop'">
          <a-tag :color="record.isTopTag.color">{{record.isTopTag.name}}</a-tag>
        </template>
        <template v-if="column.dataIndex === 'isOriginal'">
          <a-tag :color="record.isOriginalTag.color">{{record.isOriginalTag.name}}</a-tag>
        </template>
        <template v-if="column.dataIndex === 'blogTagCodeTagList'">
          <a-tag :color="item.color" v-for="(item, index) in record.blogTagCodeTagList" :key="index">{{item.name}}</a-tag>
        </template>
        <template v-if="column.dataIndex === 'action'">
          <span>
            <a-button type="link" @click="handleShow(record.id)">查看</a-button>
          </span>
            <a-popconfirm
              title="确定删除?"
              @confirm="handleDelete(record.id)"
            >
              <a-button type="link" danger v-auth:sys-blog-delete>删除</a-button>
            </a-popconfirm>
        </template>
      </template>
    </a-table>
  </wj-card>
</template>

<script>
import { defineComponent, reactive, ref, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { blogApi } from '@/util/api'
import { COLUMNS, STORE_OTHER } from '@/constant'

export default defineComponent({
  name: 'Blog',
  setup () {
    const router = useRouter()
    const store = useStore()
    const loading = ref(false)
    const list = ref([])
    const searchData = reactive({})
    const pagination = reactive({ pageSize: 10, defaultPageSize: 10, showSizeChanger: true, showTotal: (total, range) => `总计：${total} 当前：${range[0]}-${range[1]}` })
    const search = async (params) => {
      loading.value = true
      const res = await blogApi.page(params)
      loading.value = false
      if (res) {
        pagination.total = res.data.total
        list.value = res.data.records
      }
    }
    const handleSearch = () => {
      pagination.current = 1
      search({ size: pagination.pageSize, current: pagination.current, ...searchData })
    }
    const handleDelete = async id => {
      if (await blogApi.deleteById(id)) {
        handleSearch()
      }
    }
    const handleInsert = () => {
      router.push({ path: '/blog-edit' })
    }
    const handleShow = id => {
      router.push({ path: `/blog-edit/${id}` })
    }
    const paginationChangeHandler = (config) => {
      pagination.current = config.current
      pagination.pageSize = config.pageSize
      search({ size: config.pageSize, current: config.current, ...searchData })
    }
    onMounted(() => handleSearch())
    watch(() => store.state.other.refreshBlog, value => {
      if (value === true) {
        handleSearch()
        store.dispatch(`other/${STORE_OTHER.REFRESH_BLOG}`, false)
      }
    })
    return {
      COLUMNS,
      searchData,
      list,
      pagination,
      loading,
      handleSearch,
      handleDelete,
      handleInsert,
      handleShow,
      paginationChangeHandler
    }
  }
})
</script>

<style scoped>

</style>
