<template>
  <a-modal
    :visible="editModelVisible"
    title="角色"
    @cancel="cancelHandler"
    :maskClosable="false"
    width="600px"
  >
    <template v-slot:footer>
      <a-button @click="cancelHandler">退出</a-button>
      <a-button type="primary" @click="submitHandler" v-auth:sys-role-update v-if="current.id">更新</a-button>
      <a-button type="primary" @click="submitHandler" v-auth:sys-role-insert v-if="!current.id">提交</a-button>
    </template>
    <a-form :model="current" :labelCol="{span: 4}" :wrapperCol="{span: 20}" ref="formRef" :rules="RULES.ROLE">
      <a-form-item label="角色名" name="name">
        <a-input v-model:value="current.name" />
      </a-form-item>
      <a-form-item label="备注" name="remark">
        <a-input v-model:value="current.remark" />
      </a-form-item>
      <a-form-item label="菜单分配">
        <a-tree
          :checkedKeys="menuTreeCheckedList"
          :auto-expand-parent="autoExpandParent"
          :tree-data="menuTree"
          :field-names="{key: 'id'}"
          :showLine="true"
          checkable
          :checkStrictly="true"
          @check="handleCheck"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { reactive, ref, watch, onMounted } from 'vue'
import ObjectUtil from '@/util/ObjectUtil'
import { menuApi, roleApi } from '@/util/api'
import { RULES } from '@/constant'
import { message } from 'ant-design-vue'

export default {
  name: 'role-edit-modal',
  props: {
    id: {
      type: String,
      require: false
    },
    editModelVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['refresh', 'update:editModelVisible', 'update:id'],
  setup (props, context) {
    const current = reactive({})
    const menuTree = ref([])
    const menuTreeCheckedList = ref([])
    const autoExpandParent = ref(true)
    const formRef = ref()
    watch(() => props.id, async (newValue, oldValue) => {
      if (newValue) {
        const res = await roleApi.getById(newValue)
        if (res) {
          ObjectUtil.clearAttributesAndAssign(current, res.data)
          menuTreeCheckedList.value = current.menuIdList
        }
      } else {
        ObjectUtil.clearAttributes(current)
        menuTreeCheckedList.value = []
      }
    })
    const submitHandler = () => {
      formRef.value.validate().then(async () => {
        if (!current.menuIdList || current.menuIdList.length === 0) {
          message.warn('请分配菜单')
          return
        }
        let res
        if (current.id) {
          res = await roleApi.update(current)
        } else {
          res = await roleApi.insert(current)
        }
        if (res) {
          context.emit('update:editModelVisible', false)
          context.emit('update:id', null)
          context.emit('refresh')
        }
      })
    }
    const cancelHandler = () => {
      ObjectUtil.clearAttributes(current)
      context.emit('update:editModelVisible', false)
      context.emit('update:id', null)
    }
    const handleCheck = checkedKeys => {
      menuTreeCheckedList.value = checkedKeys
      current.menuIdList = checkedKeys.checked
    }
    onMounted(async () => {
      const res = await menuApi.getMenuTree()
      if (res) {
        menuTree.value = res.data
      }
    })
    return {
      current,
      autoExpandParent,
      menuTree,
      menuTreeCheckedList,
      submitHandler,
      cancelHandler,
      handleCheck,
      formRef,
      RULES
    }
  }
}
</script>

<style scoped>

</style>
