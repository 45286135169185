<template>
  <template v-if="menu.children && menu.children.length > 0 && menu.meta.isEnabled === true && menu.meta.isHide === false">
    <a-sub-menu :key="menu.meta.id">
      <template v-slot:title>
        <component :is="$antIcons[menu.meta.icon]" /><span>{{menu.meta.title}}</span>
      </template>
      <sub-menu :menu="item" v-for="item in menu.children" :key="item.meta.id"/>
    </a-sub-menu>
  </template>
  <template v-else>
    <a-menu-item :key="menu.meta.id" v-if="menu.meta.isEnabled === true && menu.meta.isHide === false">
<!--      <router-link :to="menu.path" target="_blank">-->
      <router-link :to="menu.path">
        {{menu.meta.title}}
      </router-link>
    </a-menu-item>
  </template>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SubMenu',
  props: {
    menu: {
      type: Object,
      default: () => Object
    }
  }
})
</script>
