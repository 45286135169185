<template>
  <div :style="layoutTagStyle" v-if="showTag">
    <a-dropdown :trigger="['contextmenu']" v-for="(tag, index) in visitedTags" :key="index">
      <a-tag
        :color="isActive(tag) ? 'blue': ''"
        class="tag-item" @click="push(tag)"
       :closable="tag.meta.isAffix !== true"
        @close.prevent.stop="handleClose(tag)"
        >
        {{tag.meta.title}}
      </a-tag>
      <template #overlay>
        <a-menu>
          <a-menu-item key="1" @click="refresh" v-if="isActive(tag)">刷新</a-menu-item>
          <a-menu-item key="2" v-if="tag.meta.isAffix != true" @click="handleClose(tag)">关闭</a-menu-item>
          <a-menu-item key="3" @click="handleCloseOther(tag)" v-if="isActive(tag)">关闭其他</a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, watch, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { STORE_STYLE } from '@/constant'

export default defineComponent({
  name: 'LayoutTag',
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const showTag = ref()
    const layoutTagStyle = reactive({
      display: 'flex',
      zIndex: 10,
      background: '#DDD',
      paddingLeft: '10px',
      position: 'fixed',
      top: '64px',
      width: '100%',
      height: '26px'
      // boxShadow: '0px 5px 5px #DDDDDD'
    })
    const visitedTags = computed(() => store.state.style.visitedTags)
    const push = tag => {
      router.push({
        path: tag.path,
        query: tag.query,
        params: tag.params
      })
    }
    const isActive = (tag) => {
      return tag.path === route.path
    }
    const handleClose = tag => {
      store.commit(`style/${STORE_STYLE.DEL_VISITED_TAG}`, tag)
      store.commit(`style/${STORE_STYLE.DEL_VISITED_CACHE}`, tag)
      if (isActive(tag)) {
        router.push(visitedTags.value[visitedTags.value.length - 1].path)
      }
    }
    const handleCloseOther = tag => {
      store.commit(`style/${STORE_STYLE.DEL_VISITED_OTHER_TAG}`, tag)
      store.commit(`style/${STORE_STYLE.DEL_VISITED_OTHER_CACHE}`, tag)
    }
    const refresh = () => {
      store.commit(`style/${STORE_STYLE.EXECUTE_REFRESH}`, true)
    }
    const switchFixed = value => {
      if (value === true) {
        layoutTagStyle.position = 'fixed'
        layoutTagStyle.top = '64px'
      } else {
        layoutTagStyle.position = 'relative'
        layoutTagStyle.top = '0'
      }
    }
    const initAffixTag = value => {
      showTag.value = value
      if (value) {
        const allRoute = router.getRoutes()
        allRoute.forEach(item => {
          if (item && item.meta && item.meta.isAffix === true) {
            store.commit(`style/${STORE_STYLE.ADD_VISITED_TAG}`, item)
            store.commit(`style/${STORE_STYLE.ADD_VISITED_CACHE}`, item)
          }
        })
        store.commit(`style/${STORE_STYLE.ADD_VISITED_TAG}`, route)
        store.commit(`style/${STORE_STYLE.ADD_VISITED_CACHE}`, route)
      } else {
        store.commit(`style/${STORE_STYLE.CLEAR_VISITED_TAG}`)
        store.commit(`style/${STORE_STYLE.CLEAR_VISITED_CACHE}`)
      }
    }
    watch(() => route.path, () => {
      if (route.path !== '/login') {
        store.commit(`style/${STORE_STYLE.ADD_VISITED_TAG}`, route)
        store.commit(`style/${STORE_STYLE.ADD_VISITED_CACHE}`, route)
      }
    })
    watch(() => store.state.style.fixedHeader, newValue => switchFixed(newValue))
    watch(() => store.state.style.showTag, newValue => initAffixTag(newValue))
    onMounted(() => {
      switchFixed(store.state.style.fixedHeader)
      initAffixTag(store.state.style.showTag)
    })
    return {
      push,
      layoutTagStyle,
      visitedTags,
      handleClose,
      handleCloseOther,
      refresh,
      isActive,
      showTag
    }
  }
})
</script>

<style scoped>
  .tag-item {
    cursor: pointer;
    align-self: center;
  }
</style>
