<template>
  <a-modal
    :visible="editModelVisible"
    title="用户"
    :maskClosable="false"
    @cancel="cancelHandler"
  >
    <template v-slot:footer>
      <a-button @click="cancelHandler">取消</a-button>
      <a-button type="primary" @click="submitHandler" v-auth:sys-user-update>提交</a-button>
    </template>
    <a-form :model="currentUser" :labelCol="{span: 4}" :wrapperCol="{span: 20}" ref="formRef" :rules="RULES.USER">
      <a-form-item label="用户名" name="username">
        <a-input v-model:value="currentUser.username" :disabled="currentUser.id ? true : false"/>
      </a-form-item>
      <a-form-item label="角色" name="roleIdList">
        <a-select
          mode="multiple"
          v-model:value="currentUser.roleIdList"
          :allowClear="true"
        >
          <a-select-option v-for="item in roles" :key="item.id">
            {{item.name}}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="邮箱" name="email">
        <a-input v-model:value="currentUser.email" />
      </a-form-item>
      <a-form-item label="状态" name="isEnabled">
        <a-switch checked-children="启用" un-checked-children="禁用" v-model:checked="currentUser.isEnabled" />
      </a-form-item>
      <a-form-item label="有效期" name="validPeriodTemp">
        <a-date-picker show-time v-model:value="currentUser.validPeriodTemp" style="width: 100%"/>
<!--        <a-date-picker v-model:value="currentUser.validPeriodTemp" style="width: 100%"/>-->
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { reactive, onMounted, ref, watch } from 'vue'
import ObjectUtil from '@/util/ObjectUtil'
import dayjs from 'dayjs'
import { message } from 'ant-design-vue'
import { roleApi, userApi } from '@/util/api'
import { RULES } from '@/constant'

export default {
  name: 'user-edit-modal',
  props: {
    userId: {
      type: String,
      require: false
    },
    editModelVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['refresh', 'update:editModelVisible', 'update:userId'],
  setup (props, context) {
    // const { user } = toRefs(props)
    const currentUser = reactive({})
    const roles = ref([])
    const formRef = ref()
    watch(() => props.userId, async (newValue, oldValue) => {
      if (newValue) {
        const res = await userApi.getById(newValue)
        if (res) {
          ObjectUtil.clearAttributesAndAssign(currentUser, res.data)
          if (currentUser.validPeriod) {
            currentUser.validPeriodTemp = ref(dayjs(currentUser.validPeriod, 'YYYY-MM-DD HH:mm:ss'))
          }
        }
      } else {
        ObjectUtil.clearAttributesAndAssign(currentUser, { isEnabled: true })
      }
    })
    const initRoles = async () => {
      const res = await roleApi.list()
      if (res) {
        roles.value = res.data
      }
    }
    onMounted(() => {
      initRoles()
    })
    const submitHandler = () => {
      formRef.value.validate().then(async () => {
        if (currentUser.validPeriodTemp) {
          currentUser.validPeriod = dayjs(currentUser.validPeriodTemp).format('YYYY-MM-DD HH:mm:ss')
        } else {
          delete currentUser.validPeriod
        }
        const tempUser = JSON.parse(JSON.stringify(currentUser))
        const tempRoleIdList = JSON.parse(JSON.stringify(currentUser.roleIdList))
        delete tempUser.roleIdList
        delete tempUser.validPeriodTemp
        message.loading({ content: 'Loading...', key: 'addUserLoading' })
        let res
        if (currentUser.id) {
          res = await userApi.update({ sysUser: tempUser, roleIdList: tempRoleIdList })
        } else {
          res = await userApi.insert({ sysUser: tempUser, roleIdList: tempRoleIdList })
        }
        if (res) {
          message.success({
            content: res.msg,
            key: 'addUserLoading',
            duration: 2,
            onClose: () => {
              ObjectUtil.clearAttributes(currentUser)
              context.emit('update:editModelVisible', false)
              context.emit('update:userId', null)
              context.emit('refresh')
            }
          })
        }
      })
    }
    const cancelHandler = () => {
      ObjectUtil.clearAttributes(currentUser)
      context.emit('update:editModelVisible', false)
      context.emit('update:userId', null)
    }
    return {
      currentUser,
      roles,
      submitHandler,
      cancelHandler,
      formRef,
      RULES
    }
  }
}
</script>

<style scoped>

</style>
