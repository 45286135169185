<template>
  <wj-card>
    <a-form
      layout="inline"
      :model="searchData"
    >
      <a-form-item label="角色名">
        <a-input v-model:value="searchData.name"></a-input>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="searchHandler">
          搜索
        </a-button>
        <a-button type="primary" @click="addModalHandler" v-auth:sys-role-insert>
          新增
        </a-button>
      </a-form-item>
    </a-form>
  </wj-card>
  <wj-card>
    <a-table size="small" :columns="COLUMNS.ROLE" :data-source="roles" bordered :pagination="pagination" @change="paginationChangeHandler" :loading="loading" :rowKey="record=>record.id">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'action'">
          <span>
            <a-button type="link" @click="editModalHandler(record)">编辑</a-button>
          </span>
          <a-popconfirm
            title="确定删除?"
            @confirm="deleteHandler(record.id)"
          >
            <a-button type="link" danger v-auth:sys-role-delete>删除</a-button>
          </a-popconfirm>
        </template>
      </template>
    </a-table>
  </wj-card>
  <role-edit-modal v-model:id="modalRoleId" v-model:editModelVisible="modelVisible" @refresh="searchHandler"/>
</template>

<script>
import { defineComponent, reactive, ref, onMounted } from 'vue'
import { roleApi } from '@/util/api'
import RoleEditModal from '@/views/sys/role/pages/editModal'
import { COLUMNS } from '@/constant'

export default defineComponent({
  name: 'SysRole',
  components: { RoleEditModal },
  setup () {
    const loading = ref(false)
    const roles = ref([])
    const modalRoleId = ref()
    const modelVisible = ref(false)
    const searchData = reactive({})
    const pagination = reactive({ pageSize: 10, defaultPageSize: 10, showSizeChanger: true, showTotal: (total, range) => `总计：${total} 当前：${range[0]}-${range[1]}` })
    const search = async (params) => {
      loading.value = true
      const res = await roleApi.page(params)
      loading.value = false
      if (res) {
        pagination.total = res.data.total
        roles.value = res.data.records
      }
    }
    const searchHandler = () => {
      pagination.current = 1
      search({ size: pagination.pageSize, current: pagination.current, ...searchData })
    }
    const deleteHandler = async id => {
      if (await roleApi.deleteById(id)) {
        searchHandler()
      }
    }
    const addModalHandler = () => {
      modalRoleId.value = null
      modelVisible.value = true
    }
    const editModalHandler = record => {
      modalRoleId.value = record.id
      modelVisible.value = true
    }
    const paginationChangeHandler = (config) => {
      pagination.current = config.current
      pagination.pageSize = config.pageSize
      search({ size: config.pageSize, current: config.current, ...searchData })
    }
    onMounted(() => searchHandler())
    return {
      COLUMNS,
      searchData,
      roles,
      pagination,
      loading,
      modelVisible,
      modalRoleId,
      searchHandler,
      deleteHandler,
      addModalHandler,
      editModalHandler,
      paginationChangeHandler
    }
  }
})
</script>

<style scoped>

</style>
