import { createStore } from 'vuex'
// import createPersistedState from 'vuex-persistedstate'
import style from '@/store/modules/style'
import user from '@/store/modules/user'
import other from '@/store/modules/other'

export default createStore({
  // plugins: process.env.NODE_ENV === 'production' ? [createPersistedState()] : [],
  // plugins: [createPersistedState({
  //   storage: window.localStorage,
  //   paths: ['style']
  // })],
  modules: {
    style,
    user,
    other
  }
})
