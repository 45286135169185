<template>
  <wj-card>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
    <div>Dashboard</div>
  </wj-card>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Dashboard'
})
</script>

<style scoped>

</style>
