<template>
  <a-layout-content :style="layoutContentStyle">
    <slot>
      main
    </slot>
  </a-layout-content>
</template>

<script>
import { defineComponent, onMounted, reactive, watch } from 'vue'
import { useStore } from 'vuex'
export default defineComponent({
  name: 'LayoutContent',
  setup () {
    const store = useStore()
    const layoutContentStyle = reactive({
      width: '100%',
      padding: '88px 10px 10px 10px'
    })
    const switchFixed = () => {
      const fixedHeader = store.state.style.fixedHeader
      const showTag = store.state.style.showTag
      if (fixedHeader === true && showTag === false) {
        layoutContentStyle.padding = '78px 10px 10px 10px'
      } else if (fixedHeader === true && showTag === true) {
        layoutContentStyle.padding = '103px 10px 10px 10px'
      } else {
        layoutContentStyle.padding = '14px 10px 10px 10px'
      }
    }
    watch(() => store.state.style.fixedHeader, newValue => switchFixed())
    watch(() => store.state.style.showTag, newValue => switchFixed())
    onMounted(() => switchFixed())
    return {
      layoutContentStyle
    }
  }
})
</script>
