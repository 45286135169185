<template>
  <a-form :model="current" :labelCol="{span: 4}" :wrapperCol="{span: 20}">
    <a-form-item label="类型">
      <a-select
        v-model:value="current.type"
        disabled
      >
        <a-select-option v-for="item in typeOptions" :key="item">
          {{item}}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item label="标题">
      <a-input v-model:value="current.title" />
    </a-form-item>
    <a-form-item label="上级菜单">
      <a-tree-select
        v-model:value="current.parentId"
        style="width: 100%"
        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
        :tree-data="currentTree"
        :field-names="{key: 'id', value: 'id'}"
        treeDefaultExpandAll
      >
        <template v-slot:title="item">
          <span style="color: grey" >{{item.title}}</span>
        </template>
      </a-tree-select>
    </a-form-item>
    <a-form-item label="名称" v-if="current.type === 'MENU'">
      <a-input v-model:value="current.name" />
    </a-form-item>
    <a-form-item label="前段路由" v-if="current.type === 'MENU'">
      <a-input v-model:value="current.path" />
    </a-form-item>
    <a-form-item label="组件"  v-if="current.type === 'MENU'">
      <a-input v-model:value="current.component"/>
    </a-form-item>
    <a-form-item label="图标" v-if="current.type === 'CATALOG'">
      <wj-icon-picker v-model:icon="current.icon">
        <template #iconSelect>
          <a-input v-model:value="current.icon" />
        </template>
      </wj-icon-picker>
<!--      <a-input v-model:value="current.icon" />-->
    </a-form-item>
    <a-form-item label="权限代码" v-if="current.type === 'OPERATE'">
      <a-input v-model:value="current.code"/>
    </a-form-item>
    <a-form-item label="是否隐藏" v-if="current.type !== 'OPERATE'">
      <a-switch checked-children="是" un-checked-children="否" v-model:checked="current.isHide" />
    </a-form-item>
    <a-form-item label="状态">
      <a-switch checked-children="启用" un-checked-children="禁用" v-model:checked="current.isEnabled" />
    </a-form-item>
    <a-form-item label="缓存" v-if="current.type === 'MENU'">
      <a-switch checked-children="启用" un-checked-children="禁用" v-model:checked="current.keepAlive" />
    </a-form-item>
    <a-form-item label="排序" v-if="current.type !== 'OPERATE'">
      <a-input-number v-model:value="current.orderIndex" :min="1"/>
    </a-form-item>
    <a-form-item label="资源配置" v-if="current.type === 'OPERATE'">
      <a-button type="link" @click="handleSetResourceModalVisible">{{ current.resourceIdList && current.resourceIdList.length > 0 ? '已配置' : '未配置'}}</a-button>
      <set-resource-modal v-model:modalVisible="setResourceModalVisible" :resourceIdList="currentResourceIdList" @setResourceIdList="handleSetResourceIdList"/>
    </a-form-item>
  </a-form>
  <div style="width: 100%;text-align: right">
    <a-popconfirm
      title="确定删除?"
      @confirm="handleDelete"
    >
      <a-button type="primary" danger v-if="current.id" v-auth:sys-menu-delete>删除</a-button>
    </a-popconfirm>
    <a-popconfirm
      title="确定更新?"
      @confirm="handleUpdate"
    >
      <a-button type="primary" v-if="current.id" v-auth:sys-menu-update>更新</a-button>
    </a-popconfirm>
    <a-popconfirm
      title="确定提交?"
      @confirm="handleInsert"
    >
      <a-button type="primary" v-if="!current.id" v-auth:sys-menu-insert>提交</a-button>
    </a-popconfirm>
  </div>
</template>

<script>
import { reactive, watch, ref } from 'vue'
import ObjectUtil from '@/util/ObjectUtil'
import SetResourceModal from '@/views/sys/menu/pages/setResourceModal'
import WjIconPicker from '@/components/select/WjIconPicker'
import { menuApi } from '@/util/api'
const typeOptions = ['CATALOG', 'MENU', 'OPERATE']
const getParentTypeList = type => {
  switch (type) {
    case 'OPERATE' : return ['MENU']
    case 'MENU': return ['CATALOG']
    case 'CATALOG': return ['CATALOG']
  }
}
export default {
  name: 'menu-edit-form',
  components: { SetResourceModal, WjIconPicker },
  props: {
    menu: {
      type: Object,
      require: true
    },
    menuTree: {
      type: Array,
      default: () => []
    }
  },
  emits: ['refresh'],
  setup (props, context) {
    const setResourceModalVisible = ref(false)
    const current = reactive({})
    const currentTree = ref([])
    const currentResourceIdList = ref([])
    const handleSetResourceModalVisible = () => {
      currentResourceIdList.value = current.resourceIdList
      setResourceModalVisible.value = true
    }
    const setCurrentTreeSelectable = type => {
      const selectableTypeList = getParentTypeList(type)
      const setHelper = list => {
        list.forEach(item => {
          if (selectableTypeList.indexOf(item.type) > -1) {
            item.disabled = false
          } else {
            item.disabled = true
          }
          if (item.children && item.children.length > 0) {
            setHelper(item.children)
          }
        })
      }
      setHelper(currentTree.value)
    }
    watch(() => props.menuTree, newValue => {
      currentTree.value = [{ title: '顶级', id: '-1', type: 'CATALOG', children: JSON.parse(JSON.stringify(newValue)) }]
    }, { immediate: true })
    watch(() => props.menu, async newValue => {
      if (newValue.id) {
        const res = await menuApi.getById(newValue.id)
        if (res) {
          setCurrentTreeSelectable(res.data.type)
          ObjectUtil.clearAttributesAndAssign(current, res.data)
        }
      } else if (newValue.type) {
        setCurrentTreeSelectable(newValue.type)
        ObjectUtil.clearAttributesAndAssign(current, { type: newValue.type, isEnabled: true, isHide: false })
      } else {
        ObjectUtil.clearAttributes(current)
      }
    }, { deep: true, immediate: true })
    const handleDelete = async () => {
      const res = await menuApi.deleteById(current.id)
      res && context.emit('refresh')
    }
    const handleUpdate = async () => {
      const res = await menuApi.update(current)
      res && context.emit('refresh')
    }
    const handleInsert = async () => {
      const res = await menuApi.insert(current)
      res && context.emit('refresh')
    }
    const handleSetResourceIdList = (value) => {
      current.resourceIdList = value
    }
    return {
      typeOptions,
      current,
      currentTree,
      currentResourceIdList,
      setResourceModalVisible,
      handleDelete,
      handleUpdate,
      handleInsert,
      handleSetResourceModalVisible,
      handleSetResourceIdList
    }
  }
}
</script>

<style scoped>

</style>
