<template>
  <a-layout-header :style="layoutHeaderStyle">
    <a-row>
      <a-col :span="12">
        <menu-unfold-outlined
            v-if="siderCollapse"
            class="trigger"
            @click="siderCollapsedToggle"
        />
        <menu-fold-outlined v-else class="trigger" @click="siderCollapsedToggle" />
      </a-col>
      <a-col :span="12" style="text-align: right;padding-right: 40px">
        <a-dropdown placement="bottomCenter">
          <a-avatar size="large" v-if="!avatar">
            <template #icon><UserOutlined /></template>
          </a-avatar>
          <a-avatar size="large" v-else :src="avatar"/>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <a @click.prevent.stop="handleUserInfo">个人中心</a>
              </a-menu-item>
              <a-menu-item>
                <a @click.prevent.stop="handleLogout">退出登录</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </a-col>
    </a-row>
  </a-layout-header>
</template>

<script>
import { defineComponent, computed, reactive, watch, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { STORE_STYLE, STORE_USER } from '@/constant'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined
} from '@ant-design/icons-vue'
export default defineComponent({
  name: 'LayoutHeader',
  components: {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined
  },
  setup () {
    const router = useRouter()
    const store = useStore()
    const avatar = ref()
    const handleLogout = async () => {
      await store.dispatch(`user/${STORE_USER.LOGOUT}`)
    }
    const handleUserInfo = () => {
      router.push('/user_info')
    }
    const layoutHeaderStyle = reactive({
      zIndex: 10,
      background: '#fff',
      padding: 0,
      position: 'fixed',
      top: 0,
      right: 0,
      width: '100%',
      transitionDuration: '0.2s'
    })
    const switchFixed = () => {
      if (store.state.style.fixedHeader === true && store.state.style.siderCollapse === false) {
        layoutHeaderStyle.width = 'calc(100% - 200px)'
        layoutHeaderStyle.position = 'fixed'
        // rightColStyle.paddingRight = '230px'
      } else if (store.state.style.fixedHeader === true && store.state.style.siderCollapse === true) {
        layoutHeaderStyle.width = 'calc(100% - 80px)'
        layoutHeaderStyle.position = 'fixed'
      } else {
        layoutHeaderStyle.position = ''
        layoutHeaderStyle.width = '100%'
      }
    }
    watch(() => store.state.style.fixedHeader, () => switchFixed())
    watch(() => store.state.style.siderCollapse, () => switchFixed())
    onMounted(() => {
      switchFixed(store.state.style.fixedHeader)
      avatar.value = store.state.user.user.avatar
    })
    const siderCollapsedToggle = () => store.dispatch(`style/${STORE_STYLE.TOGGLE_SIDER_COLLAPSED}`)
    return {
      layoutHeaderStyle,
      siderCollapse: computed(() => store.state.style.siderCollapse),
      siderCollapsedToggle,
      handleLogout,
      handleUserInfo,
      avatar
    }
  }
})
</script>

<style scoped>
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: #1890ff;
  }
</style>
