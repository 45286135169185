<template>
  <a-layout>
    <wj-style-affix/>
    <layout-sider/>
    <a-layout class="right-transition" :style="layoutMarginLeftStyle">
      <layout-header/>
      <layout-tag/>
      <layout-content>
        <!-- vue3.x 需要这样写 keep-alive才能生效 -->
        <router-view v-slot="{ Component }" v-if="!isRefresh">
          <keep-alive :include="visitedCaches">
            <component :is="Component" :key="$route.fullPath"/>
          </keep-alive>
        </router-view>
      </layout-content>
      <layout-footer/>
    </a-layout>
  </a-layout>
</template>
<script>
import { defineComponent, ref, computed, nextTick, watch } from 'vue'
import { useStore } from 'vuex'
import LayoutSider from '@/components/layout/LayoutSider.vue'
import LayoutHeader from '@/components/layout/LayoutHeader.vue'
import LayoutContent from '@/components/layout/LayoutContent.vue'
import LayoutFooter from '@/components/layout/LayoutFooter.vue'
import LayoutTag from '@/components/layout/LayoutTag'
import WjStyleAffix from '@/components/affix/WjStyleAffix'
import { STORE_STYLE } from '@/constant'
export default defineComponent({
  name: 'index',
  components: {
    LayoutSider,
    LayoutHeader,
    LayoutContent,
    LayoutFooter,
    WjStyleAffix,
    LayoutTag
  },
  setup () {
    const store = useStore()
    const isRefresh = ref(false)
    watch(() => store.state.style.executeRefresh, value => {
      if (value === true) {
        isRefresh.value = true
        nextTick(() => {
          isRefresh.value = false
        })
        store.commit(`style/${STORE_STYLE.EXECUTE_REFRESH}`, false)
      }
    })
    return {
      isRefresh,
      selectedKeys: ref(['1']),
      layoutMarginLeftStyle: computed(() => store.state.style.siderCollapse ? { marginLeft: '80px' } : { marginLeft: '200px' }),
      visitedCaches: computed(() => store.state.style.visitedCaches)
    }
  }
})
</script>
<style scoped>
  .right-transition {
    transition:margin 0.2s;
    -moz-transition:margin 0.2s;
    -webkit-transition:margin 0.2s;
    -o-transition:margin 0.2s;
  }
</style>
