import {
  Button,
  Form,
  Row,
  Col,
  Switch,
  ConfigProvider,
  Drawer,
  Layout,
  Dropdown,
  Avatar,
  Menu,
  Tag,
  Input,
  Modal,
  Table,
  Popconfirm,
  Card,
  Select,
  Tree,
  Empty,
  TreeSelect,
  InputNumber,
  Transfer,
  Upload,
  Tabs,
  DatePicker,
  Popover
} from 'ant-design-vue'

const list = [
  Button,
  Form,
  Row,
  Col,
  Switch,
  ConfigProvider,
  Drawer,
  Layout,
  Dropdown,
  Avatar,
  Menu,
  Tag,
  Input,
  Modal,
  Table,
  Popconfirm,
  Card,
  Select,
  Tree,
  Empty,
  TreeSelect,
  InputNumber,
  Transfer,
  Upload,
  Tabs,
  DatePicker,
  Popover
]
export const setupAntd = app => {
  list.forEach(item => {
    app.use(item)
  })
}
