import { STORE_OTHER } from '@/constant'

const other = {
  namespaced: true,
  state: {
    refreshBlog: false
  },
  mutations: {
    [STORE_OTHER.REFRESH_BLOG]: (state, data) => {
      state.refreshBlog = data
    }
  },
  actions: {
    [STORE_OTHER.REFRESH_BLOG]: ({ commit }, data) => {
      commit(STORE_OTHER.REFRESH_BLOG, data)
    }
  }
}
export default other
