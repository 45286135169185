<template>
  <a-modal
    :visible="editModelVisible"
    title="异常信息"
    :maskClosable="true"
    @cancel="cancelHandler"
    width="60%"
  >
    <template v-slot:footer>
      <a-button @click="cancelHandler">退出</a-button>
    </template>
    <a-form>
      <a-form-item label="异常信息">
        <a-textarea v-model:value="currentLog.errorInfo" readonly :autosize="true"/>
      </a-form-item>
      <a-form-item label="异常堆栈" v-if="currentLog.errorStacktrace">
        <a-textarea v-model:value="currentLog.errorStacktrace" readonly :autosize="true"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { reactive, watch } from 'vue'
import ObjectUtil from '@/util/ObjectUtil'
import { logApi } from '@/util/api'

export default {
  name: 'log-error-modal',
  props: {
    logId: {
      type: String,
      require: false
    },
    editModelVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:editModelVisible'],
  setup (props, context) {
    const currentLog = reactive({})
    watch(() => props.logId, async (newValue, oldValue) => {
      if (newValue) {
        const res = await logApi.getById(newValue)
        if (res) {
          ObjectUtil.clearAttributesAndAssign(currentLog, res.data)
        }
      } else {
        ObjectUtil.clearAttributes(currentLog)
      }
    })
    const cancelHandler = () => {
      context.emit('update:editModelVisible', false)
    }
    return {
      cancelHandler,
      currentLog
    }
  }
}
</script>

<style scoped>

</style>
