import { REQUEST_HEADER, STORE_STYLE, STORE_USER } from '@/constant'
import { userApi, authApi } from '@/util/api'
import router from '@/router'
import * as storage from '@/util/cookie'

const user = {
  namespaced: true,
  state: {
    user: {},
    param: {},
    roles: [],
    menus: [],
    menuOriginal: [],
    authCodeSet: [],
    isLogin: false
  },
  mutations: {
    [STORE_USER.INIT_USER]: (state, data) => {
      state.user = data.user
      state.roles = data.roles
      state.authCodeSet = data.authCodeSet
      state.menuOriginal = data.menus
      state.param = data.param
      initMenus(state, data.menus)
      state.isLogin = true
    },
    [STORE_USER.LOGOUT]: state => {
      state.isLogin = false
      storage.removeToken(REQUEST_HEADER.ACCESS_TOKEN)
      storage.removeToken(REQUEST_HEADER.REFRESH_TOKEN)
      router.push('/login')
    }
  },
  actions: {
    [STORE_USER.LOGIN]: async ({ commit }, data) => {
      const res = await authApi.login(data)
      if (res) {
        if (res.data === true) {
          const initUserRes = await userApi.initUser()
          if (initUserRes) {
            commit(`style/${STORE_STYLE.INIT_STYLE}`, initUserRes.data.style, { root: true })
            commit(STORE_USER.INIT_USER, initUserRes.data)
            router.push('/')
          }
        } else {
          return Promise.reject(new Error('密码不满足安全规则'))
        }
      }
    },
    [STORE_USER.LOGOUT]: async ({ commit }) => {
      const res = await authApi.logout()
      if (res) {
        commit(STORE_USER.LOGOUT)
      }
    }
  }
}

const initMenus = (state, menus) => {
  state.menus = menusToTree(menus)
}

const toMeta = (menus) => {
  const menuMetas = []
  menus.forEach(item => {
    menuMetas.push({
      name: item.name,
      path: item.path,
      component: () => import(`@/views/${item.component}.vue`),
      meta: {
        parentId: item.parentId,
        id: item.id,
        title: item.title,
        icon: item.icon,
        type: item.type,
        isEnabled: item.isEnabled,
        keepAlive: item.keepAlive,
        isHide: item.isHide
      },
      children: []
    })
  })
  return menuMetas
}

const menusToTreeHelper = (menuMetas, child) => {
  const meta = child.meta
  menuMetas.forEach(item => {
    const itemMeta = item.meta
    if (meta.id === itemMeta.parentId) {
      if (!child.children) {
        child.children = []
      }
      child.children.push(item)
      menusToTreeHelper(menuMetas, item)
    }
  })
}

const menusToTree = (menus) => {
  const menuMetas = toMeta(menus)
  const route = menuMetas.filter(item => item.meta.type === 'MENU')
  route.forEach(item => {
    // router.addRoute(item)
    router.addRoute('index', item)
  })
  const topMenus = menuMetas.filter(item => item.meta.parentId === '-1')
  topMenus.forEach(top => {
    menusToTreeHelper(menuMetas, top)
  })
  return topMenus
}

export default user
