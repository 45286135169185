<template>
  <a-modal
    :visible="modalVisible"
    title="资源"
    @cancel="cancelHandler"
    :maskClosable="false"
  >
    <template v-slot:footer>
      <a-button @click="cancelHandler">取消</a-button>
      <a-button type="primary" @click="submitHandler" v-auth:sys-resource-update v-if="current.id">更新</a-button>
      <a-button type="primary" @click="submitHandler" v-auth:sys-resource-insert v-if="!current.id">提交</a-button>
    </template>
    <a-form :model="current" :labelCol="{span: 8}" :wrapperCol="{span: 16}" ref="formRef" :rules="RULES.RESOURCE">
      <a-form-item label="名称" name="name">
        <a-input v-model:value="current.name" />
      </a-form-item>
      <a-form-item label="路由" name="url">
        <a-input v-model:value="current.url" />
      </a-form-item>
      <a-form-item label="请求方法" name="method">
        <wj-dict-select :type-code="DICT_TYPE.REQUEST_METHOD" v-model:value="current.method"/>
      </a-form-item>
      <a-form-item label="模块" name="applicationName">
        <wj-dict-select :type-code="DICT_TYPE.APPLICATION_NAME" v-model:value="current.applicationName"/>
      </a-form-item>
      <a-form-item label="类型" name="type">
        <wj-dict-select :type-code="DICT_TYPE.RESOURCE_TYPE" v-model:value="current.type"/>
      </a-form-item>
      <a-form-item label="状态">
        <a-switch checked-children="启用" un-checked-children="禁用" v-model:checked="current.isEnabled" />
      </a-form-item>
      <a-form-item label="记录日志">
        <a-switch checked-children="启用" un-checked-children="禁用" v-model:checked="current.recordLog"/>
      </a-form-item>
      <a-form-item label="记录请求参数">
        <a-switch checked-children="启用" un-checked-children="禁用" v-model:checked="current.recordRequestData" />
      </a-form-item>
      <a-form-item label="记录响应数据">
        <a-switch checked-children="启用" un-checked-children="禁用" v-model:checked="current.recordResponseData" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { reactive, watch, ref } from 'vue'
import ObjectUtil from '@/util/ObjectUtil'
import { resourceApi } from '@/util/api'
import { DICT_TYPE, RULES } from '@/constant'
import WjDictSelect from '@/components/select/WjDictSelect'
export default {
  name: 'resource-edit-modal',
  components: { WjDictSelect },
  props: {
    id: {
      type: String,
      require: false
    },
    modalVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['refresh', 'update:modalVisible', 'update:id'],
  setup (props, context) {
    // const { user } = toRefs(props)
    const current = reactive({})
    const formRef = ref()
    watch(() => props.id, async (newValue, oldValue) => {
      if (newValue) {
        const res = await resourceApi.getById(newValue)
        if (res) {
          ObjectUtil.clearAttributesAndAssign(current, res.data)
        }
      } else {
        ObjectUtil.clearAttributesAndAssign(current, { isEnabled: false, recordLog: false, recordRequestData: false, recordResponseData: false })
      }
    })
    const submitHandler = () => {
      formRef.value.validate().then(async () => {
        let res
        if (current.id) {
          res = await resourceApi.update(current)
        } else {
          res = await resourceApi.insert(current)
        }
        if (res) {
          ObjectUtil.clearAttributesAndAssign(current, { isEnabled: false, recordLog: false, recordRequestData: false, recordResponseData: false })
          context.emit('update:modalVisible', false)
          context.emit('update:id', null)
          context.emit('refresh')
        }
      })
    }
    const cancelHandler = () => {
      ObjectUtil.clearAttributesAndAssign(current, { isEnabled: false, recordLog: false, recordRequestData: false, recordResponseData: false })
      context.emit('update:modalVisible', false)
      context.emit('update:id', null)
    }
    return {
      current,
      submitHandler,
      cancelHandler,
      formRef,
      RULES,
      DICT_TYPE
    }
  }
}
</script>

<style scoped>

</style>
