import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/components/layout/Layout.vue'
import store from '@/store'
import NProgress from 'nprogress'
import { userApi } from '@/util/api'
import { REQUEST_HEADER, STORE_STYLE, STORE_USER } from '@/constant'
import * as storage from '@/util/cookie'

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/dashboard',
    component: Layout,
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('../views/Dashboard.vue'),
        meta: {
          title: '首页',
          keepAlive: false,
          isAffix: true
        }
      },
      {
        path: '/user_info',
        name: 'UserInfo',
        component: () => import('../views/sys/userInfo/index.vue'),
        meta: {
          title: '个人信息',
          keepAlive: false,
          isAffix: false
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: 'login',
      keepAlive: false,
      isAffix: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from) => {
  NProgress.start()
  const accessToken = storage.getToken(REQUEST_HEADER.ACCESS_TOKEN)
  const refreshToken = storage.getToken(REQUEST_HEADER.REFRESH_TOKEN)
  if (store.state.user.isLogin) {
    if (to.path === '/login') {
      return '/'
    }
  } else if (accessToken && refreshToken) {
    const initUserRes = await userApi.initUser()
    if (initUserRes) {
      store.commit(`user/${STORE_USER.INIT_USER}`, initUserRes.data)
      store.commit(`style/${STORE_STYLE.INIT_STYLE}`, initUserRes.data.style)
      if (to.path === '/login') {
        return '/'
      } else {
        return to.fullPath
      }
    }
  } else if (to.path !== '/login') {
    return '/login'
  }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
