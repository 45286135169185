import { STORE_STYLE } from '@/constant'
import { styleApi } from '@/util/api'
import ObjectUtil from '@/util/ObjectUtil'
const style = {
  namespaced: true,
  state: {
    // 侧边栏收缩
    siderCollapse: false,
    // 是否固定header
    fixedHeader: true,
    showTag: true,
    visitedTags: [],
    visitedCaches: [],
    executeRefresh: false,
    inlineLabelCol: { style: { minWidth: '70px' } },
    inlineWrapperCol: { style: { width: '120px' } }
  },
  mutations: {
    [STORE_STYLE.INIT_STYLE]: (state, style) => {
      state.siderCollapse = style.siderCollapse
      state.fixedHeader = style.fixedHeader
      state.showTag = style.showTag
    },
    [STORE_STYLE.TOGGLE_FIXED_HEADER]: (state, fixedHeader) => {
      state.fixedHeader = fixedHeader
    },
    [STORE_STYLE.TOGGLE_SHOW_TAG]: (state, showTag) => {
      state.showTag = showTag
    },
    [STORE_STYLE.TOGGLE_SIDER_COLLAPSED]: (state) => {
      state.siderCollapse = !state.siderCollapse
    },
    [STORE_STYLE.ADD_VISITED_TAG]: (state, tag) => {
      if (state.showTag) {
        for (let index = 0; index < state.visitedTags.length; index++) {
          if (state.visitedTags[index].path === tag.path) {
            // 刷新query和params等
            ObjectUtil.clearAttributesAndAssign(state.visitedTags[index], tag)
            return
          }
        }
        state.visitedTags.push(Object.assign({}, tag))
      }
    },
    [STORE_STYLE.DEL_VISITED_TAG]: (state, tag) => {
      if (state.showTag) {
        for (const [i, v] of state.visitedTags.entries()) {
          if (v.path === tag.path) {
            state.visitedTags.splice(i, 1)
            break
          }
        }
      }
    },
    [STORE_STYLE.DEL_VISITED_OTHER_CACHE]: (state, tag) => {
      state.visitedCaches = [tag]
    },
    [STORE_STYLE.ADD_VISITED_CACHE]: (state, tag) => {
      if (state.showTag && tag.meta.keepAlive === true) {
        if (state.visitedCaches.indexOf(tag.name) > -1) {
          return
        }
        state.visitedCaches.push(tag.name)
      }
    },
    [STORE_STYLE.DEL_VISITED_CACHE]: (state, tag) => {
      if (state.showTag && tag.meta.keepAlive === true) {
        if (state.visitedTags.some(item => item.name.indexOf(tag.name) === 0)) {
          return
        }
        const index = state.visitedCaches.indexOf(tag.name)
        index > -1 && state.visitedCaches.splice(index, 1)
      }
    },
    [STORE_STYLE.DEL_VISITED_OTHER_TAG]: (state, tag) => {
      const newArr = []
      state.visitedTags.forEach(item => item.meta.isAffix && newArr.push(item))
      newArr.push(tag)
      state.visitedTags = newArr
    },
    [STORE_STYLE.CLEAR_VISITED_CACHE]: (state) => {
      state.visitedCaches = []
    },
    [STORE_STYLE.CLEAR_VISITED_TAG]: (state) => {
      state.visitedTags = []
    },
    [STORE_STYLE.EXECUTE_REFRESH]: (state, executeRefresh) => {
      state.executeRefresh = executeRefresh
    }
  },
  actions: {
    [STORE_STYLE.TOGGLE_FIXED_HEADER]: ({ commit }, fixedHeader) => {
      commit(STORE_STYLE.TOGGLE_FIXED_HEADER, fixedHeader)
      styleApi.update({ fixedHeader })
    },
    [STORE_STYLE.TOGGLE_SHOW_TAG]: ({ commit }, showTag) => {
      commit(STORE_STYLE.TOGGLE_SHOW_TAG, showTag)
      styleApi.update({ showTag })
    },
    [STORE_STYLE.TOGGLE_SIDER_COLLAPSED]: ({ state, commit }) => {
      const data = { siderCollapse: !state.siderCollapse }
      commit(STORE_STYLE.TOGGLE_SIDER_COLLAPSED)
      styleApi.update(data)
    }
  }
}
export default style
