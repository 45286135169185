<template>
  <a-layout-sider
    class="sider"
    :collapsed="siderCollapse"
    :trigger="null"
    collapsible
    :style="{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0 }">
    <div class="logo" @click="pushHome"/>
    <Menu :menus="menus"/>
  </a-layout-sider>
<!--  <a-drawer-->
<!--    title="布局"-->
<!--    placement="left"-->
<!--    v-model:visible="siderCollapse"-->
<!--    class="drawer"-->
<!--  >-->
<!--    123-->
<!--  </a-drawer>-->
</template>
<script>
import store from '@/store'
import { defineComponent, computed } from 'vue'
import { useRouter } from 'vue-router'
import Menu from '@/components/menu/Menu.vue'
export default defineComponent({
  name: 'LayoutSider',
  components: {
    Menu
  },
  setup () {
    const router = useRouter()
    const pushHome = () => {
      router.push('/')
    }
    return {
      menus: computed(() => store.state.user.menus),
      siderCollapse: computed(() => store.state.style.siderCollapse),
      pushHome
    }
  }
})
</script>

<style scoped>
  .logo {
    height: 50px;
    /*background: rgba(255, 255, 255, 0.2);*/
    background: url('../../assets/image/logo1.png') no-repeat;
    background-size: 80% 80%;
    margin: 16px;
    cursor:pointer;
  }
  /*@media screen and (max-width: 768px) {*/
  /*  .sider {*/
  /*    display: none;*/
  /*  }*/
  /*}*/
</style>
