<template>
  <a-menu
    mode="inline"
    theme="dark"
    v-model:selectedKeys="selectedKeys"
    v-model:openKeys="openKeys"
    >
    <sub-menu :menu="item" v-for="item in menus" :key="item.meta.id"/>
  </a-menu>
</template>

<script lang='ts'>
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import SubMenu from './SubMenu.vue'
const getParentIds = (childrenId, menuOriginal, tempOpenKeys) => {
  for (const item of menuOriginal) {
    if (item.id === childrenId) {
      const parentId = item.parentId
      if (parentId !== '-1') {
        tempOpenKeys.push(parentId)
        getParentIds(parentId, menuOriginal, tempOpenKeys)
      }
    }
  }
}
export default defineComponent({
  name: 'Menu',
  props: {
    menus: {
      type: Array,
      defaults: []
    }
  },
  components: { SubMenu },
  setup () {
    const route = useRoute()
    const store = useStore()
    const openKeys = ref([])
    const selectedKeys = ref([])
    const menuOriginal = store.state.user.menuOriginal
    onMounted(() => {
      const tempOpenKeys = []
      for (const item of menuOriginal) {
        if (item.path === route.path) {
          getParentIds(item.id, menuOriginal, tempOpenKeys)
          selectedKeys.value = [item.id]
          openKeys.value = tempOpenKeys
          return
        }
      }
    })
    return {
      selectedKeys,
      openKeys
    }
  }
})
</script>
