<template>
  <wj-card>
    <a-form
      layout="inline"
      :model="searchData"
      :labelCol="{style: { minWidth: '70px' }}" :wrapperCol="{style: { width: '120px' }}"
    >
      <a-form-item label="路由">
        <a-input v-model:value="searchData.url"></a-input>
      </a-form-item>
      <a-form-item label="资源名称">
        <a-input v-model:value="searchData.name"></a-input>
      </a-form-item>
      <a-form-item label="模块">
        <wj-dict-select :type-code="DICT_TYPE.APPLICATION_NAME" v-model:value="searchData.applicationName"></wj-dict-select>
      </a-form-item>
      <a-form-item label="请求方法">
        <wj-dict-select :type-code="DICT_TYPE.REQUEST_METHOD" v-model:value="searchData.method"></wj-dict-select>
      </a-form-item>
      <a-form-item label="类型">
        <wj-dict-select :type-code="DICT_TYPE.RESOURCE_TYPE" v-model:value="searchData.type"></wj-dict-select>
      </a-form-item>
      <a-button type="primary" @click="searchHandler">
        搜索
      </a-button>
      <a-button type="primary" @click="modalHandler(null)" v-auth:sys-resource-insert>
        新增
      </a-button>
    </a-form>
  </wj-card>
  <wj-card>
    <a-table size="small" :columns="COLUMNS.RESOURCE" :data-source="roles" bordered :pagination="pagination" @change="paginationChangeHandler" :loading="loading" :rowKey="record=>record.id">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'type'">
          <a-tag :color="record.typeTag.color">{{record.typeTag.name}}</a-tag>
        </template>
        <template v-if="column.dataIndex === 'method'">
          <a-tag :color="record.methodTag.color">{{record.methodTag.name}}</a-tag>
        </template>
        <template v-if="column.dataIndex === 'isEnabled'">
          <a-tag :color="record.isEnabledTag.color">{{record.isEnabledTag.name}}</a-tag>
        </template>
        <template v-if="column.dataIndex === 'recordLog'">
          <a-tag :color="record.recordLogTag.color">{{record.recordLogTag.name}}</a-tag>
        </template>
        <template v-if="column.dataIndex === 'recordRequestData'">
          <a-tag :color="record.recordRequestDataTag.color">{{record.recordRequestDataTag.name}}</a-tag>
        </template>
        <template v-if="column.dataIndex === 'recordResponseData'">
          <a-tag :color="record.recordResponseDataTag.color">{{record.recordResponseDataTag.name}}</a-tag>
        </template>
        <template v-if="column.dataIndex === 'action'">
          <a-button type="link" @click="modalHandler(record.id)" v-auth:sys-resource-update>编辑</a-button>
          <a-popconfirm
            title="确定删除?"
            @confirm="deleteHandler(record.id)"
          >
            <a-button type="link" danger v-auth:sys-resource-delete>删除</a-button>
          </a-popconfirm>
        </template>
      </template>
    </a-table>
  </wj-card>
  <resource-edit-modal v-model:id="modalResourceId" v-model:modalVisible="modalVisible" @refresh="refresh"/>
</template>

<script>
import { defineComponent, reactive, ref, onMounted } from 'vue'
import ResourceEditModal from '@/views/sys/resource/pages/editModal'
import { resourceApi } from '@/util/api'
import { DICT_TYPE, COLUMNS } from '@/constant'
import WjDictSelect from '@/components/select/WjDictSelect'

export default defineComponent({
  name: 'SysResource',
  components: { WjDictSelect, ResourceEditModal },
  setup () {
    const loading = ref(false)
    const roles = ref([])
    const modalResourceId = ref()
    const modalVisible = ref(false)
    const searchData = reactive({})
    const pagination = reactive({ pageSize: 10, defaultPageSize: 10, showSizeChanger: true, showTotal: (total, range) => `总计：${total} 当前：${range[0]}-${range[1]}` })
    const search = async (params) => {
      loading.value = true
      const res = await resourceApi.page(params)
      loading.value = false
      if (res) {
        pagination.total = res.data.total
        roles.value = res.data.records
      }
    }
    const searchHandler = () => {
      pagination.current = 1
      search({ size: pagination.pageSize, current: pagination.current, ...searchData })
    }
    const deleteHandler = async id => {
      if (await resourceApi.deleteById(id)) {
        searchHandler()
      }
    }
    const modalHandler = (id) => {
      modalResourceId.value = id
      modalVisible.value = true
    }
    const paginationChangeHandler = (config) => {
      pagination.current = config.current
      pagination.pageSize = config.pageSize
      search({ size: config.pageSize, current: config.current, ...searchData })
    }
    const refresh = () => {
      searchHandler()
    }
    onMounted(() => {
      searchHandler()
    })
    return {
      COLUMNS,
      searchData,
      roles,
      pagination,
      loading,
      modalResourceId,
      modalVisible,
      refresh,
      searchHandler,
      deleteHandler,
      modalHandler,
      paginationChangeHandler,
      DICT_TYPE
    }
  }
})
</script>

<style scoped>

</style>
