<template>
  <a-select
    v-model:value="currentValue"
    :allow-clear="true"
    :mode="mode"
  >
    <a-select-option v-for="item in options" :key="item.code">
      {{item.name}}
    </a-select-option>
  </a-select>
</template>

<script>
import { ref, onMounted, watch } from 'vue'
import { dictApi } from '@/util/api'

export default {
  name: 'WjDictSelect',
  props: {
    typeCode: {
      type: String,
      required: true
    },
    value: {
      type: [String, Array],
      required: false
    },
    mode: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['update:value'],
  setup (props, context) {
    const options = ref([])
    const currentValue = ref()
    const onChange = (value) => {
      context.emit('update:value', value)
    }
    watch(() => props.value, value => {
      currentValue.value = value
    })
    watch(() => currentValue.value, value => {
      context.emit('update:value', value)
    })
    onMounted(async () => {
      const res = await dictApi.itemList(props.typeCode)
      if (res) {
        options.value = res.data
      }
    })
    return {
      options,
      onChange,
      currentValue
    }
  }
}
</script>

<style>

.clicked-index {
  background: lightblue;
}

</style>
