<template>
  <wj-card>
    <a-form
      layout="inline"
      :model="searchData"
      :labelCol="{style: { minWidth: '70px' }}" :wrapperCol="{style: { width: '120px' }}"
    >
      <a-form-item label="用户名">
        <a-input v-model:value="searchData.username"></a-input>
      </a-form-item>
      <a-form-item label="IP">
        <a-input v-model:value="searchData.ip"></a-input>
      </a-form-item>
      <a-form-item label="类型">
        <wj-dict-select :type-code="DICT_TYPE.LOGIN_LOG_TYPE" v-model:value="searchData.type"/>
      </a-form-item>
      <a-form-item label="状态">
        <wj-dict-select :type-code="DICT_TYPE.SUCCESS_FAIL_STATUS" v-model:value="searchData.status"/>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="searchHandler">
          搜索
        </a-button>
      </a-form-item>
    </a-form>
  </wj-card>
  <wj-card>
    <a-table size="small" :columns="COLUMNS.LOGIN_LOG" :data-source="logs" bordered :pagination="pagination" @change="paginationChangeHandler" :loading="loading" :rowKey="record=>record.id">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'status'">
          <a-tag :color="record.statusTag.color">{{record.statusTag.name}}</a-tag>
        </template>
        <template v-if="column.dataIndex === 'type'">
          <a-tag :color="record.typeTag.color">{{record.typeTag.name}}</a-tag>
        </template>
      </template>
    </a-table>
  </wj-card>
</template>

<script>
import { defineComponent, reactive, ref, onMounted } from 'vue'
import { loginLogApi } from '@/util/api'
import { DICT_TYPE, COLUMNS } from '@/constant'
import WjDictSelect from '@/components/select/WjDictSelect'

export default defineComponent({
  name: 'SysLoginLog',
  components: { WjDictSelect },
  setup () {
    const loading = ref(false)
    const logs = ref([])
    const searchData = reactive({})
    const pagination = reactive({ pageSize: 10, defaultPageSize: 10, showSizeChanger: true, showTotal: (total, range) => `总计：${total} 当前：${range[0]}-${range[1]}` })
    onMounted(() => {
      search({ size: pagination.defaultPageSize, current: 1 })
    })
    const paginationChangeHandler = (config) => {
      pagination.current = config.current
      pagination.pageSize = config.pageSize
      search({ size: config.pageSize, current: config.current, ...searchData })
    }
    const search = async (params) => {
      loading.value = true
      const res = await loginLogApi.page(params)
      loading.value = false
      if (res) {
        pagination.total = res.data.total
        logs.value = res.data.records
      }
    }
    const searchHandler = () => {
      pagination.current = 1
      search({ size: pagination.pageSize, current: pagination.current, ...searchData })
    }
    return {
      COLUMNS,
      searchData,
      logs,
      pagination,
      loading,
      searchHandler,
      paginationChangeHandler,
      DICT_TYPE
    }
  }
})
</script>

<style scoped>

</style>
