<template>
  <a-modal
      :visible="editModelVisible"
      :title="'字典类型-' + currentType.name"
      :maskClosable="false"
      @cancel="cancelHandler"
  >
    <template v-slot:footer>
      <a-button @click="cancelHandler">取消</a-button>
      <a-popconfirm
          title="确定更新?"
          @confirm="handleUpdate"
      >
        <a-button type="primary" v-if="current.id" v-auth:sys-dict-update>更新</a-button>
      </a-popconfirm>
      <a-popconfirm
          title="确定提交?"
          @confirm="handleInsert"
      >
        <a-button type="primary" v-if="!current.id" v-auth:sys-dict-insert>提交</a-button>
      </a-popconfirm>
    </template>
    <a-form :model="current" :labelCol="{span: 4}" :wrapperCol="{span: 20}" ref="formRef" :rules="RULES.DICT_ITEM">
      <a-form-item label="字典项名" name="name">
        <a-input v-model:value="current.name" />
      </a-form-item>
      <a-form-item label="字典项值" name="code">
        <a-input v-model:value="current.code" />
      </a-form-item>
      <a-form-item label="颜色" name="color">
        <v-swatches
          v-model="current.color"
          show-fallback
          fallback-input-type="color"
          popover-x="left"
        ></v-swatches>
<!--        <a-select
          v-model:value="current.color"
          :allow-clear="true"
        >
          <a-select-option v-for="item in colorOptions" :key="item.code">
            {{item.name}}
          </a-select-option>
        </a-select>-->
      </a-form-item>
      <a-form-item label="排序" name="orderIndex">
        <a-input-number v-model:value="current.orderIndex" :min="1"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { reactive, watch, ref } from 'vue'
import ObjectUtil from '@/util/ObjectUtil'
import { dictApi } from '@/util/api'
import { RULES } from '@/constant'

const colorOptions = [
  {
    code: 'green',
    name: 'GREEN'
  },
  {
    code: 'red',
    name: 'RED'
  },
  {
    code: 'orange',
    name: 'ORANGE'
  },
  {
    code: 'blue',
    name: 'BLUE'
  }
]
export default {
  name: 'menu-edit-form',
  props: {
    id: {
      type: String,
      require: false
    },
    editModelVisible: {
      type: Boolean,
      default: false
    },
    currentType: {
      type: Object,
      require: true
    }
  },
  emits: ['refresh', 'update:editModelVisible', 'update:id'],
  setup (props, context) {
    const current = reactive({})
    const formRef = ref()
    watch(() => props.id, async id => {
      if (id) {
        const res = await dictApi.getItemById(id)
        if (res) {
          ObjectUtil.clearAttributesAndAssign(current, res.data)
        }
      } else {
        ObjectUtil.clearAttributesAndAssign(current, { typeCode: props.currentType.code, orderIndex: 1 })
      }
    }, { deep: true, immediate: true })
    const handleUpdate = () => {
      formRef.value.validate().then(async () => {
        const res = await dictApi.updateItem(current)
        if (res) {
          context.emit('update:editModelVisible', false)
          context.emit('update:id', null)
          context.emit('refresh')
        }
      })
    }
    const handleInsert = () => {
      formRef.value.validate().then(async () => {
        const res = await dictApi.insertItem(current)
        if (res) {
          context.emit('update:editModelVisible', false)
          context.emit('update:id', '')
          context.emit('refresh')
        }
      })
    }
    const cancelHandler = () => {
      ObjectUtil.clearAttributes(current)
      context.emit('update:editModelVisible', false)
      context.emit('update:id', '')
    }
    return {
      current,
      handleUpdate,
      handleInsert,
      cancelHandler,
      formRef,
      RULES,
      colorOptions
    }
  }
}
</script>

<style scoped>

</style>
