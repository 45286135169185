import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import WjCard from '@/components/card/WjCard'
import JsonViewer from 'vue3-json-viewer'
import VSwatches from 'vue3-swatches'
import { setupAntd } from '@/setup_antd'
// import Antd from 'ant-design-vue'
// import 'ant-design-vue/dist/antd.css'
import * as antIcons from '@ant-design/icons-vue'
import 'nprogress/nprogress.css'
// -------------------------------------------markdown编辑器
import VMdEditor from '@kangc/v-md-editor/lib/codemirror-editor'
import '@kangc/v-md-editor/lib/style/codemirror-editor.css'
// import githubTheme from '@kangc/v-md-editor/lib/theme/github.js'
// import '@kangc/v-md-editor/lib/theme/style/github.css'
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'

// highlightjs github主题使用
// import hljs from 'highlight.js'

// codemirror 编辑器的相关资源
import Codemirror from 'codemirror'
// mode
import 'codemirror/mode/markdown/markdown'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/mode/css/css'
import 'codemirror/mode/htmlmixed/htmlmixed'
import 'codemirror/mode/vue/vue'
// edit
import 'codemirror/addon/edit/closebrackets'
import 'codemirror/addon/edit/closetag'
import 'codemirror/addon/edit/matchbrackets'
// placeholder
import 'codemirror/addon/display/placeholder'
// active-line
import 'codemirror/addon/selection/active-line'
// scrollbar
import 'codemirror/addon/scroll/simplescrollbars'
import 'codemirror/addon/scroll/simplescrollbars.css'
// style
import 'codemirror/lib/codemirror.css'
import Prism from 'prismjs'

import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index'
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css'
import createKatexPlugin from '@kangc/v-md-editor/lib/plugins/katex/cdn'
// ------------------------------------markdown编辑器

VMdEditor.Codemirror = Codemirror
VMdEditor.use(vuepressTheme, {
  // Hljs: hljs, github主题使用
  Prism // vuepress主题使用高亮包
}).use(createCopyCodePlugin()).use(createKatexPlugin())
const app = createApp(App)
setupAntd(app)
app.use(store).use(router).use(JsonViewer).use(VMdEditor).use(VSwatches).mount('#app')
app.component('wj-card', WjCard)
app.config.productionTip = true
app.config.globalProperties.$antIcons = antIcons
/**
 * (必须先判断el是否存在，因为v-if表达式为false会导致el不存在，导致el.remove()报错)
 * (el.remove() 会导致v-if异常改用 display)
 *
 * 自定义指令 挂在在mounted生命周期el.remove()才生效
 * 或者 想在 mounted 和 updated 时触发相同行为，而不关心其他的钩子函数。那么你可以通过将这个回调函数传递给指令来实现：
 * app.directive('auth', (el, auth) => {
 *  const authCodeSet = store.state.user.authCodeSet
 *  if (!authCodeSet || authCodeSet.length === 0 || authCodeSet.indexOf(auth.value) === -1) {
 *    el.remove()
 *  }
 * })
 */
app.directive('auth', (el, auth) => {
  const authCodeSet = store.state.user.authCodeSet
  const isNotInclude = code => {
    return !authCodeSet || authCodeSet.length === 0 || authCodeSet.indexOf(code) === -1
  }
  if (isNotInclude(auth.arg)) {
    el.style.display = 'none'
  }
})
