<template>
    <a-row :gutter="16">
      <a-col class="gutter-row" :span="6">
        <wj-card>
          <a-card :bordered="false">
            <template v-slot:title>
              菜单树
              <div style="display: inline-block" v-auth:sys-menu-insert>
                <a-dropdown>
                  <a class="ant-dropdown-link" @click.prevent>
                    新增
                    <DownOutlined />
                  </a>
                  <template #overlay>
                    <a-menu  @click="handleMenuClick">
                      <a-menu-item key="CATALOG">CATALOG</a-menu-item>
                      <a-menu-item key="MENU">MENU</a-menu-item>
                      <a-menu-item key="OPERATE">OPERATE</a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
            </template>
            <a-tree
              :selectedKeys="selectedKeys"
              :expandedKeys="expandedKeys"
              @expand="onExpand"
              :auto-expand-parent="autoExpandParent"
              :tree-data="menuTree"
              :field-names="{key: 'id'}"
              :showLine="true"
              @select="treeClickHandler"
              >
            </a-tree>
          </a-card>
        </wj-card>
      </a-col>
      <a-col class="gutter-row" :span="18">
        <wj-card>
          <a-card :title="editTitle" :bordered="false">
            <menu-edit-form :menu="modalMenu" :menuTree="menuTree" v-if="modalMenu.id || modalMenu.type" @refresh="refresh"/>
            <a-empty :description="false" v-if="!modalMenu.id && !modalMenu.type"/>
          </a-card>
        </wj-card>
      </a-col>
    </a-row>
</template>

<script>
import { DownOutlined } from '@ant-design/icons-vue'
import { ref, onMounted, reactive } from 'vue'
import WjCard from '@/components/card/WjCard'
import MenuEditForm from '@/views/sys/menu/pages/editForm'
import ObjectUtil from '@/util/ObjectUtil'
import { menuApi } from '@/util/api'

export default {
  name: 'SysMenu',
  components: { MenuEditForm, WjCard, DownOutlined },
  setup () {
    const editTitle = ref()
    const expandedKeys = ref([])
    const selectedKeys = ref([])
    const menuTree = ref([])
    const autoExpandParent = ref(true)
    const modalMenu = reactive({})
    const onExpand = keys => {
      expandedKeys.value = keys
      autoExpandParent.value = false
    }
    const treeClickHandler = (id, e) => {
      if (id.length === 1) {
        selectedKeys.value = id
        modalMenu.id = id[0]
        editTitle.value = `${e.node.title}-编辑`
      }
    }
    const handleMenuClick = e => {
      switch (e.key) {
        case 'CATALOG':
          editTitle.value = '目录-新增'
          break
        case 'MENU':
          editTitle.value = '菜单-新增'
          break
        case 'OPERATE':
          editTitle.value = '按钮-新增'
          break
        default:
          editTitle.value = '未知'
          break
      }
      modalMenu.id = null
      modalMenu.type = e.key
      selectedKeys.value = []
    }
    const refresh = async () => {
      editTitle.value = '表单区'
      menuTree.value = []
      expandedKeys.value = []
      selectedKeys.value = []
      ObjectUtil.clearAttributesAndAssign(modalMenu, { id: null, type: null })
      const res = await menuApi.getMenuTree()
      if (res) {
        menuTree.value = res.data
        res.data.forEach(item => {
          if (item.children) {
            expandedKeys.value.push(item.id)
          }
        })
      }
    }
    onMounted(() => refresh())
    return {
      menuTree,
      expandedKeys,
      selectedKeys,
      autoExpandParent,
      onExpand,
      treeClickHandler,
      handleMenuClick,
      refresh,
      editTitle,
      modalMenu
    }
  }
}
</script>

<style scoped>

</style>
