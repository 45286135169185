import columns from '@/constant/module/columns'
import rules from '@/constant/module/rules'

export const COLUMNS = columns
export const RULES = rules

export const REQUEST_HEADER = {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token'
}

export const STORE_USER = {
  INIT_USER: 'INIT_USER',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT'
}

export const STORE_OTHER = {
  REFRESH_BLOG: 'REFRESH_BLOG'
}

export const STORE_STYLE = {
  TOGGLE_FIXED_HEADER: 'TOGGLE_FIXED_HEADER',
  TOGGLE_SIDER_COLLAPSED: 'TOGGLE_SIDER_COLLAPSED',
  TOGGLE_SHOW_TAG: 'TOGGLE_SHOW_TAG',
  ADD_VISITED_TAG: 'ADD_VISITED_TAG',
  DEL_VISITED_TAG: 'DEL_VISITED_TAG',
  DEL_VISITED_CACHE: 'DEL_VISITED_CACHE',
  CLEAR_VISITED_CACHE: 'CLEAR_VISITED_CACHE',
  CLEAR_VISITED_TAG: 'CLEAR_VISITED_TAG',
  ADD_VISITED_CACHE: 'ADD_VISITED_CACHE',
  INIT_STYLE: 'INIT_STYLE',
  DEL_VISITED_OTHER_TAG: 'DEL_VISITED_OTHER_TAG',
  DEL_VISITED_OTHER_CACHE: 'DEL_VISITED_OTHER_CACHE',
  EXECUTE_REFRESH: 'EXECUTE_REFRESH'
}

export const HTTP_METHOD = {
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
  DELETE: 'DELETE'
}

export const DICT_TYPE = {
  APPLICATION_NAME: 'application-name',
  REQUEST_METHOD: 'request-method',
  RESOURCE_TYPE: 'resource-type',
  LOG_STATUS: 'log-status',
  SUCCESS_FAIL_STATUS: 'success-fail-status',
  BLOG_TAG: 'blog-tag',
  LOGIN_LOG_TYPE: 'login-log-type'
}
