<template>
  <div id="login">
    <div class="login-form">
      <a-form :label-col="labelCol" :wrapper-col="wrapperCol" ref="loginRef" :rules="RULES.LOGIN" :model="param">
        <a-form-item>
          <img class="logo" src="../assets/image/logo.png" />
          <div class="head">WJ FAST</div>
<!--          <div class="desc">明 湖 区 最 具 影 响 力 的 设 计 规 范 之 一</div>-->
        </a-form-item>
        <a-form-item name="username">
          <a-input placeholder="账 户" v-model:value="param.username" />
        </a-form-item>
        <a-form-item name="password">
          <a-input
            placeholder="密 码"
            v-model:value="param.password"
            type="password"
            @keyup.enter="onSubmit"
          />
        </a-form-item>
<!--        <a-form-item>-->
<!--          <a-checkbox :checked="true">-->
<!--            记住我-->
<!--          </a-checkbox>-->
<!--          <a class="forgot" href=""> 忘记密码 </a>-->
<!--        </a-form-item>-->
        <a-form-item :wrapper-col="{ span: 24 }">
          <wj-sliding-verification-code
            :show="isVerificationShow"
            @success="verificationSuccess"
            @close="verificationClose"
            @fail="verificationFail"
          />
          <a-button @click="handleSliding">
            滑动验证
            <template #icon>
              <CheckOutlined style="color: #36b368" v-if="verificationCheckStatus === 1"/>
              <CloseOutlined style="color: #ce594b" v-if="verificationCheckStatus === 2"/>
            </template>
          </a-button>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 24 }">
          <a-button :loading="load" type="primary" @click="onSubmit" style="height: 42px !important;">
            登录
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
  <a-modal
    :visible="passwordModelVisible"
    title="修改密码（密码复杂度不足）"
    :closable="false"
    :maskClosable="false"
    width="600px"
  >
    <template v-slot:footer>
      <a-button type="primary" @click="handlePasswordSubmit">提交</a-button>
    </template>
    <a-form :model="passwordForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" ref="passwordRef" :rules="passwordRules">
      <a-form-item label="用户名" name="username">
        <a-input v-model:value="passwordForm.username" disabled/>
      </a-form-item>
<!--      <a-form-item label="原密码" name="password">
        <a-input-password v-model:value="passwordForm.password"/>
      </a-form-item>-->
      <a-form-item label="新密码" name="newPassword">
        <a-input-password v-model:value="passwordForm.newPassword"/>
      </a-form-item>
      <a-form-item label="确认密码" name="againPassword">
        <a-input-password v-model:value="passwordForm.againPassword"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { STORE_USER, RULES } from '@/constant'
import { authApi } from '@/util/api'
import ObjectUtil from '@/util/ObjectUtil'
import WjSlidingVerificationCode from '@/components/WjSlidingVerificationCode'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { encrypt } from '@/util/Rsa'

export default {
  components: { WjSlidingVerificationCode, CheckOutlined, CloseOutlined },
  setup () {
    const isVerificationShow = ref(false)
    const verificationCheckStatus = ref(0)
    const handleSliding = () => {
      isVerificationShow.value = true
    }
    const verificationSuccess = () => {
      verificationCheckStatus.value = 1
      isVerificationShow.value = false
    }
    const verificationClose = () => {
      verificationCheckStatus.value = 2
      isVerificationShow.value = false
    }
    const verificationFail = () => {
      verificationCheckStatus.value = 2
    }
    const store = useStore()
    const passwordForm = reactive({})
    const passwordRef = ref()
    const loginRef = ref()
    const passwordModelVisible = ref(false)
    const param = reactive({ username: '', password: '' })
    const load = ref(false)
    const validateNewPassword = async (rule, value) => {
      if (value === '') {
        return Promise.reject(new Error('请输入新密码'))
      } else {
        if (passwordForm.newPassword !== '') {
          passwordRef.value.validateFields('againPassword')
        }
        return Promise.resolve()
      }
    }
    const validateAgainPassword = async (rule, value) => {
      if (value === '') {
        return Promise.reject(new Error('新密码和确认密码不相同'))
      } else if (value !== passwordForm.newPassword) {
        return Promise.reject(new Error('新密码和确认密码不相同'))
      } else {
        return Promise.resolve()
      }
    }
    const handlePasswordSubmit = () => {
      passwordRef.value.validate().then(async () => {
        const requestPasswordChangeData = {
          username: passwordForm.username,
          password: encrypt(passwordForm.password),
          newPassword: encrypt(passwordForm.newPassword),
          againPassword: encrypt(passwordForm.againPassword)
        }
        const res = await authApi.passwordChangeByLogin(requestPasswordChangeData)
        if (res) {
          param.password = ''
          passwordModelVisible.value = false
          ObjectUtil.clearAttributes(passwordForm)
        }
      })
    }
    const passwordRules = {
      username: [
        {
          required: true,
          message: '请输入用户名',
          trigger: ['change', 'blur'],
          whitespace: true
        }
      ],
      password: [
        {
          required: true,
          message: '请输入原密码',
          trigger: ['change', 'blur'],
          whitespace: true
        }
      ],
      newPassword: [
        {
          required: true,
          validator: validateNewPassword,
          trigger: ['change', 'blur']
        }
      ],
      againPassword: [
        {
          required: true,
          validator: validateAgainPassword,
          trigger: ['change', 'blur']
        }
      ]
    }
    const onSubmit = e => {
      e.preventDefault()
      loginRef.value.validate().then(async () => {
        if (verificationCheckStatus.value === 1) {
          load.value = true
          try {
            const requestData = { username: param.username, password: encrypt(param.password) }
            await store.dispatch(`user/${STORE_USER.LOGIN}`, requestData)
          } catch (e) {
            passwordForm.username = param.username
            passwordForm.password = param.password
            passwordModelVisible.value = true
          }
          load.value = false
          verificationCheckStatus.value = 0
        } else {
          message.error('请进行滑动验证！')
        }
      })
    }
    return {
      RULES,
      labelCol: { span: 6 },
      wrapperCol: { span: 24 },
      onSubmit,
      handlePasswordSubmit,
      param,
      load,
      passwordRules,
      passwordForm,
      passwordModelVisible,
      passwordRef,
      loginRef,
      isVerificationShow,
      handleSliding,
      verificationSuccess,
      verificationClose,
      verificationFail,
      verificationCheckStatus
    }
  }
}
</script>
<style lang="less" scoped>
  #login {
    width: 100%;
    height: 100vh;
    background: url(../assets/image/background.svg);
    background-size: cover;
    .login-form {
      margin: auto;
      width: 340px;
      min-height: 20px;
      padding-top: 150px;
      .ant-input {
        border-radius: 4px;
        line-height: 42px;
        height: 42px;
      }
      .ant-btn {
        width: 100%;
      }
    }
    .logo {
      width: 60px !important;
      margin-top: 10px !important;
      margin-bottom: 10px !important;
      margin-left: 20px !important;
      border: none;
      background-color: transparent;
    }
    .head {
      width: 300px;
      font-size: 30px !important;
      font-weight: 700 !important;
      margin-left: 20px !important;
      line-height: 60px !important;
      margin-top: 10px !important;
      position: absolute !important;
      display: inline-block !important;
      height: 60px !important;
      color: #36b368;
    }
    .desc {
      width: 100% !important;
      text-align: center !important;
      color: gray !important;
      height: 60px !important;
      line-height: 60px !important;
    }
    .forgot {
      float: right;
    }
  }
</style>
